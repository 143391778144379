/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
// import auth from "@/auth/authService";
import store from "./store/store";
import { auth } from "@/auth/auth";
import "firebase/auth";
import VueCookies from "vue-cookies";
import nurseRoutes from "./routes/nurse"
import doctorRoutes from "./routes/doctor"
import basicRoutes from "./routes/basic"
import orgOwnerRoutes from "./routes/orgOwner"
import supplierRoutes from "./routes/supplier"
Vue.use(VueCookies);

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // scrollBehavior() {
  //   return { x: 0, y: 0 };
  // },
  scrollBehavior(to, from, savedPosition) {
    if(to.hash && to.hash === "#products"){
      return {
        selector: to.hash,
        offset: { x: 0, y: 200 }
      }  
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/admin",
      name: "Admin",
      meta: {
        rule: "editor",
      },
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        {
          path: "login",
          name: "login",
          component: () => import("@/views/pages/login/Login.vue"),
          meta: {
            pageTitle: "Login",
            rule: "editor",
          },
        },
      ],
    },
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          redirect: "/login",
        },
        {
          path: "/change-password",
          name: "change-password",
          component: () => import("@/views/pages/changePassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/register/franchise",
          name: "pages-register-franchise",
          component: () =>
            import("@/views/pages/register/FranchiseRegister.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/register/clinic",
          name: "pages-register-clinic",
          component: () => import("@/views/pages/register/ClinicRegister.vue"),
          meta: {
            rule: "admin",
          },
        },
        // {
        //   path: "/register/doctor",
        //   name: "pages-register-doctor",
        //   component: () => import("@/views/pages/register/DoctorRegister.vue"),
        //   meta: {
        //     rule: "admin"
        //   }
        // },

        // =============================================================================
        // Application Routes
        // =============================================================================
        {
          path: "/apps/todo",
          redirect: "/apps/todo/all",
          name: "todo",
        },
        {
          path: "/apps/todo/:filter",
          component: () => import("./views/apps/todo/Todo.vue"),
          meta: {
            rule: "editor",
            parent: "todo",
            no_scroll: true,
          },
        },
        {
          path: "/apps/chat",
          name: "chat",
          component: () => import("./views/apps/chat/Chat.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
          },
        },
        {
          path: "/apps/email",
          redirect: "/apps/email/inbox",
          name: "email",
        },
        {
          path: "/apps/email/:filter",
          component: () => import("./views/apps/email/Email.vue"),
          meta: {
            rule: "editor",
            parent: "email",
            no_scroll: true,
          },
        },
        {
          path: "/apps/calendar/vue-simple-calendar",
          name: "calendar-simple-calendar",
          component: () => import("./views/apps/calendar/SimpleCalendar.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
          },
        },
        {
          path: "/apps/eCommerce/shop",
          name: "ecommerce-shop",
          component: () => import("./views/apps/eCommerce/ECommerceShop.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "eCommerce" },
              { title: "Shop", active: true },
            ],
            pageTitle: "Shop",
            rule: "editor",
          },
        },
        {
          path: "/apps/eCommerce/wish-list",
          name: "ecommerce-wish-list",
          component: () =>
            import("./views/apps/eCommerce/ECommerceWishList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "eCommerce", url: "/apps/eCommerce/checkout" },
              { title: "Wish List", active: true },
            ],
            pageTitle: "Wish List",
            rule: "editor",
          },
        },
        {
          path: "/apps/eCommerce/checkout",
          name: "ecommerce-checkout",
          component: () =>
            import("./views/apps/eCommerce/ECommerceCheckout.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "eCommerce", url: "/apps/eCommerce/shop" },
              { title: "Checkout", active: true },
            ],
            pageTitle: "Checkout",
            rule: "editor",
          },
        },
        /*
                  Below route is for demo purpose
                  You can use this route in your app
                    {
                        path: '/apps/eCommerce/item/',
                        name: 'ecommerce-item-detail-view',
                        redirect: '/apps/eCommerce/shop',
                    }
                */
        {
          path: "/apps/eCommerce/item/",
          redirect: "/apps/eCommerce/item/5546604",
        },
        {
          path: "/apps/eCommerce/item/:item_id",
          name: "ecommerce-item-detail-view",
          component: () =>
            import("./views/apps/eCommerce/ECommerceItemDetailView.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "eCommerce" },
              { title: "Shop", url: { name: "ecommerce-shop" } },
              { title: "Item Details", active: true },
            ],
            parent: "ecommerce-item-detail-view",
            pageTitle: "Item Details",
            rule: "editor",
          },
        },
        {
          path: "/apps/user/user-list",
          name: "app-user-list",
          component: () => import("@/views/apps/user/user-list/UserList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "User" },
              { title: "List", active: true },
            ],
            pageTitle: "User List",
            rule: "editor",
          },
        },
        {
          path: "/apps/user/user-view/:userId",
          name: "app-user-view",
          component: () => import("@/views/apps/user/UserView.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "User" },
              { title: "View", active: true },
            ],
            pageTitle: "User View",
            rule: "editor",
          },
        },
        {
          path: "/apps/user/user-edit/:userId",
          name: "app-user-edit",
          component: () => import("@/views/apps/user/user-edit/UserEdit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "User" },
              { title: "Edit", active: true },
            ],
            pageTitle: "User Edit",
            rule: "editor",
          },
        },
        // =============================================================================
        // UI ELEMENTS
        // =============================================================================
        {
          path: "/ui-elements/data-list/list-view",
          name: "data-list-list-view",
          component: () =>
            import(
              "@/views/ui-elements/data-list/list-view/DataListListView.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Data List" },
              { title: "List View", active: true },
            ],
            pageTitle: "List View",
            rule: "editor",
          },
        },
        {
          path: "/ui-elements/data-list/thumb-view",
          name: "data-list-thumb-view",
          component: () =>
            import(
              "@/views/ui-elements/data-list/thumb-view/DataListThumbView.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Data List" },
              { title: "Thumb View", active: true },
            ],
            pageTitle: "Thumb View",
            rule: "editor",
          },
        },
        {
          path: "/ui-elements/grid/vuesax",
          name: "grid-vuesax",
          component: () =>
            import("@/views/ui-elements/grid/vuesax/GridVuesax.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Grid" },
              { title: "Vuesax", active: true },
            ],
            pageTitle: "Grid",
            rule: "editor",
          },
        },
        {
          path: "/ui-elements/grid/tailwind",
          name: "grid-tailwind",
          component: () =>
            import("@/views/ui-elements/grid/tailwind/GridTailwind.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Grid" },
              { title: "Tailwind", active: true },
            ],
            pageTitle: "Tailwind Grid",
            rule: "editor",
          },
        },
        {
          path: "/ui-elements/colors",
          name: "colors",
          component: () => import("./views/ui-elements/colors/Colors.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Colors", active: true },
            ],
            pageTitle: "Colors",
            rule: "editor",
          },
        },
        {
          path: "/ui-elements/card/basic",
          name: "basic-cards",
          component: () => import("./views/ui-elements/card/CardBasic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Card" },
              { title: "Basic Cards", active: true },
            ],
            pageTitle: "Basic Cards",
            rule: "editor",
          },
        },
        {
          path: "/ui-elements/card/statistics",
          name: "statistics-cards",
          component: () =>
            import("./views/ui-elements/card/CardStatistics.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Card" },
              { title: "Statistics Cards", active: true },
            ],
            pageTitle: "Statistics Card",
            rule: "editor",
          },
        },
        {
          path: "/ui-elements/card/analytics",
          name: "analytics-cards",
          component: () => import("./views/ui-elements/card/CardAnalytics.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Card" },
              { title: "Analytics Card", active: true },
            ],
            pageTitle: "Analytics Card",
            rule: "editor",
          },
        },
        {
          path: "/ui-elements/card/card-actions",
          name: "card-actions",
          component: () => import("./views/ui-elements/card/CardActions.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Card" },
              { title: "Card Actions", active: true },
            ],
            pageTitle: "Card Actions",
            rule: "editor",
          },
        },
        {
          path: "/ui-elements/card/card-colors",
          name: "card-colors",
          component: () => import("./views/ui-elements/card/CardColors.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Card" },
              { title: "Card Colors", active: true },
            ],
            pageTitle: "Card Colors",
            rule: "editor",
          },
        },
        {
          path: "/ui-elements/table",
          name: "table",
          component: () => import("./views/ui-elements/table/Table.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Table", active: true },
            ],
            pageTitle: "Table",
            rule: "editor",
          },
        },
        {
          path: "/ui-elements/ag-grid-table",
          name: "ag-grid-table",
          component: () =>
            import("./views/ui-elements/ag-grid-table/AgGridTable.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "agGrid Table", active: true },
            ],
            pageTitle: "agGrid Table",
            rule: "editor",
          },
        },

        // =============================================================================
        // COMPONENT ROUTES
        // =============================================================================
        {
          path: "/components/alert",
          name: "component-alert",
          component: () => import("@/views/components/vuesax/alert/Alert.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Alert", active: true },
            ],
            pageTitle: "Alert",
            rule: "editor",
          },
        },
        {
          path: "/components/avatar",
          name: "component-avatar",
          component: () =>
            import("@/views/components/vuesax/avatar/Avatar.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Avatar", active: true },
            ],
            pageTitle: "Avatar",
            rule: "editor",
          },
        },
        {
          path: "/components/breadcrumb",
          name: "component-breadcrumb",
          component: () =>
            import("@/views/components/vuesax/breadcrumb/Breadcrumb.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Breadcrumb", active: true },
            ],
            pageTitle: "Breadcrumb",
            rule: "editor",
          },
        },
        {
          path: "/components/button",
          name: "component-button",
          component: () =>
            import("@/views/components/vuesax/button/Button.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Button", active: true },
            ],
            pageTitle: "Button",
            rule: "editor",
          },
        },
        {
          path: "/components/button-group",
          name: "component-button-group",
          component: () =>
            import("@/views/components/vuesax/button-group/ButtonGroup.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Button Group", active: true },
            ],
            pageTitle: "Button Group",
            rule: "editor",
          },
        },
        {
          path: "/components/chip",
          name: "component-chip",
          component: () => import("@/views/components/vuesax/chip/Chip.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Chip", active: true },
            ],
            pageTitle: "Chip",
            rule: "editor",
          },
        },
        {
          path: "/components/collapse",
          name: "component-collapse",
          component: () =>
            import("@/views/components/vuesax/collapse/Collapse.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Collapse", active: true },
            ],
            pageTitle: "Collapse",
            rule: "editor",
          },
        },
        {
          path: "/components/dialogs",
          name: "component-dialog",
          component: () =>
            import("@/views/components/vuesax/dialogs/Dialogs.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Dialogs", active: true },
            ],
            pageTitle: "Dialogs",
            rule: "editor",
          },
        },
        {
          path: "/components/divider",
          name: "component-divider",
          component: () =>
            import("@/views/components/vuesax/divider/Divider.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Divider", active: true },
            ],
            pageTitle: "Divider",
            rule: "editor",
          },
        },
        {
          path: "/components/dropdown",
          name: "component-drop-down",
          component: () =>
            import("@/views/components/vuesax/dropdown/Dropdown.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Dropdown", active: true },
            ],
            pageTitle: "Dropdown",
            rule: "editor",
          },
        },
        {
          path: "/components/list",
          name: "component-list",
          component: () => import("@/views/components/vuesax/list/List.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "List", active: true },
            ],
            pageTitle: "List",
            rule: "editor",
          },
        },
        {
          path: "/components/loading",
          name: "component-loading",
          component: () =>
            import("@/views/components/vuesax/loading/Loading.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Loading", active: true },
            ],
            pageTitle: "Loading",
            rule: "editor",
          },
        },
        {
          path: "/components/navbar",
          name: "component-navbar",
          component: () =>
            import("@/views/components/vuesax/navbar/Navbar.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Navbar", active: true },
            ],
            pageTitle: "Navbar",
            rule: "editor",
          },
        },
        {
          path: "/components/notifications",
          name: "component-notifications",
          component: () =>
            import("@/views/components/vuesax/notifications/Notifications.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Notifications", active: true },
            ],
            pageTitle: "Notifications",
            rule: "editor",
          },
        },
        {
          path: "/components/pagination",
          name: "component-pagination",
          component: () =>
            import("@/views/components/vuesax/pagination/Pagination.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Pagination", active: true },
            ],
            pageTitle: "Pagination",
            rule: "editor",
          },
        },
        {
          path: "/components/popup",
          name: "component-popup",
          component: () => import("@/views/components/vuesax/popup/Popup.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Popup", active: true },
            ],
            pageTitle: "Popup",
            rule: "editor",
          },
        },
        {
          path: "/components/progress",
          name: "component-progress",
          component: () =>
            import("@/views/components/vuesax/progress/Progress.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Progress", active: true },
            ],
            pageTitle: "Progress",
            rule: "editor",
          },
        },
        {
          path: "/components/sidebar",
          name: "component-sidebar",
          component: () =>
            import("@/views/components/vuesax/sidebar/Sidebar.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Sidebar", active: true },
            ],
            pageTitle: "Sidebar",
            rule: "editor",
          },
        },
        {
          path: "/components/slider",
          name: "component-slider",
          component: () =>
            import("@/views/components/vuesax/slider/Slider.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Slider", active: true },
            ],
            pageTitle: "Slider",
            rule: "editor",
          },
        },
        {
          path: "/components/tabs",
          name: "component-tabs",
          component: () => import("@/views/components/vuesax/tabs/Tabs.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Tabs", active: true },
            ],
            pageTitle: "Tabs",
            rule: "editor",
          },
        },
        {
          path: "/components/tooltip",
          name: "component-tooltip",
          component: () =>
            import("@/views/components/vuesax/tooltip/Tooltip.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Tooltip", active: true },
            ],
            pageTitle: "Tooltip",
            rule: "editor",
          },
        },
        {
          path: "/components/upload",
          name: "component-upload",
          component: () =>
            import("@/views/components/vuesax/upload/Upload.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Upload", active: true },
            ],
            pageTitle: "Upload",
            rule: "editor",
          },
        },

        // =============================================================================
        // FORMS
        // =============================================================================
        // =============================================================================
        // FORM ELEMENTS
        // =============================================================================
        {
          path: "/forms/form-elements/select",
          name: "form-element-select",
          component: () =>
            import("./views/forms/form-elements/select/Select.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Form Elements" },
              { title: "Select", active: true },
            ],
            pageTitle: "Select",
            rule: "editor",
          },
        },
        {
          path: "/forms/form-elements/switch",
          name: "form-element-switch",
          component: () =>
            import("./views/forms/form-elements/switch/Switch.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Form Elements" },
              { title: "Switch", active: true },
            ],
            pageTitle: "Switch",
            rule: "editor",
          },
        },
        {
          path: "/forms/form-elements/checkbox",
          name: "form-element-checkbox",
          component: () =>
            import("./views/forms/form-elements/checkbox/Checkbox.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Form Elements" },
              { title: "Checkbox", active: true },
            ],
            pageTitle: "Checkbox",
            rule: "editor",
          },
        },
        {
          path: "/forms/form-elements/radio",
          name: "form-element-radio",
          component: () =>
            import("./views/forms/form-elements/radio/Radio.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Form Elements" },
              { title: "Radio", active: true },
            ],
            pageTitle: "Radio",
            rule: "editor",
          },
        },
        {
          path: "/forms/form-elements/input",
          name: "form-element-input",
          component: () =>
            import("./views/forms/form-elements/input/Input.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Form Elements" },
              { title: "Input", active: true },
            ],
            pageTitle: "Input",
            rule: "editor",
          },
        },
        {
          path: "/forms/form-elements/number-input",
          name: "form-element-number-input",
          component: () =>
            import("./views/forms/form-elements/number-input/NumberInput.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Form Elements" },
              { title: "Number Input", active: true },
            ],
            pageTitle: "Number Input",
            rule: "editor",
          },
        },
        {
          path: "/forms/form-elements/textarea",
          name: "form-element-textarea",
          component: () =>
            import("./views/forms/form-elements/textarea/Textarea.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Form Elements" },
              { title: "Textarea", active: true },
            ],
            pageTitle: "Textarea",
            rule: "editor",
          },
        },
        // -------------------------------------------------------------------------------------------------------------------------------------------
        {
          path: "/forms/form-layouts",
          name: "forms-form-layouts",
          component: () => import("@/views/forms/FormLayouts.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Forms" },
              { title: "Form Layouts", active: true },
            ],
            pageTitle: "Form Layouts",
            rule: "editor",
          },
        },
        {
          path: "/forms/form-wizard",
          name: "extra-component-form-wizard",
          component: () => import("@/views/forms/form-wizard/FormWizard.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extra Components" },
              { title: "Form Wizard", active: true },
            ],
            pageTitle: "Form Wizard",
            rule: "editor",
          },
        },
        {
          path: "/forms/form-validation",
          name: "extra-component-form-validation",
          component: () =>
            import("@/views/forms/form-validation/FormValidation.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extra Components" },
              { title: "Form Validation", active: true },
            ],
            pageTitle: "Form Validation",
            rule: "editor",
          },
        },
        {
          path: "/forms/form-input-group",
          name: "extra-component-form-input-group",
          component: () =>
            import("@/views/forms/form-input-group/FormInputGroup.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extra Components" },
              { title: "Form Input Group", active: true },
            ],
            pageTitle: "Form Input Group",
            rule: "editor",
          },
        },

        // =============================================================================
        // Pages Routes
        // =============================================================================

        {
          path: "/pages/profile",
          name: "page-profile",
          component: () => import("@/views/pages/Profile.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages" },
              { title: "Profile", active: true },
            ],
            pageTitle: "Profile",
            rule: "editor",
          },
        },
        {
          path: "/pages/user-settings",
          name: "page-user-settings",
          component: () =>
            import("@/views/pages/user-settings/UserSettings.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages" },
              { title: "User Settings", active: true },
            ],
            pageTitle: "Settings",
            rule: "editor",
          },
        },
        {
          path: "/pages/faq",
          name: "page-faq",
          component: () => import("@/views/pages/Faq.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages" },
              { title: "FAQ", active: true },
            ],
            pageTitle: "FAQ",
            rule: "editor",
          },
        },
        {
          path: "/pages/knowledge-base",
          name: "page-knowledge-base",
          component: () => import("@/views/pages/KnowledgeBase.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages" },
              { title: "KnowledgeBase", active: true },
            ],
            pageTitle: "KnowledgeBase",
            rule: "editor",
          },
        },
        {
          path: "/pages/knowledge-base/category",
          name: "page-knowledge-base-category",
          component: () => import("@/views/pages/KnowledgeBaseCategory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages" },
              { title: "KnowledgeBase", url: "/pages/knowledge-base" },
              { title: "Category", active: true },
            ],
            parent: "page-knowledge-base",
            rule: "editor",
          },
        },
        {
          path: "/pages/knowledge-base/category/question",
          name: "page-knowledge-base-category-question",
          component: () =>
            import("@/views/pages/KnowledgeBaseCategoryQuestion.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages" },
              { title: "KnowledgeBase", url: "/pages/knowledge-base" },
              { title: "Category", url: "/pages/knowledge-base/category" },
              { title: "Question", active: true },
            ],
            parent: "page-knowledge-base",
            rule: "editor",
          },
        },
        {
          path: "/pages/search",
          name: "page-search",
          component: () => import("@/views/pages/Search.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages" },
              { title: "Search", active: true },
            ],
            pageTitle: "Search",
            rule: "editor",
          },
        },
        {
          path: "/pages/invoice",
          name: "page-invoice",
          component: () => import("@/views/pages/Invoice.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages" },
              { title: "Invoice", active: true },
            ],
            pageTitle: "Invoice",
            rule: "editor",
          },
        },

        // =============================================================================
        // CHARTS & MAPS
        // =============================================================================
        {
          path: "/charts-and-maps/charts/apex-charts",
          name: "extra-component-charts-apex-charts",
          component: () =>
            import("@/views/charts-and-maps/charts/apex-charts/ApexCharts.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Charts & Maps" },
              { title: "Apex Charts", active: true },
            ],
            pageTitle: "Apex Charts",
            rule: "editor",
          },
        },
        {
          path: "/charts-and-maps/charts/chartjs",
          name: "extra-component-charts-chartjs",
          component: () =>
            import("@/views/charts-and-maps/charts/chartjs/Chartjs.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Charts & Maps" },
              { title: "chartjs", active: true },
            ],
            pageTitle: "chartjs",
            rule: "editor",
          },
        },
        {
          path: "/charts-and-maps/charts/echarts",
          name: "extra-component-charts-echarts",
          component: () =>
            import("@/views/charts-and-maps/charts/echarts/Echarts.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Charts & Maps" },
              { title: "echarts", active: true },
            ],
            pageTitle: "echarts",
            rule: "editor",
          },
        },
        {
          path: "/charts-and-maps/maps/google-map",
          name: "extra-component-maps-google-map",
          component: () =>
            import("@/views/charts-and-maps/maps/google-map/GoogleMap.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Charts & Maps" },
              { title: "Google Map", active: true },
            ],
            pageTitle: "Google Map",
            rule: "editor",
          },
        },

        // =============================================================================
        // EXTENSIONS
        // =============================================================================
        {
          path: "/extensions/select",
          name: "extra-component-select",
          component: () =>
            import("@/views/components/extra-components/select/Select.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extra Components" },
              { title: "Select", active: true },
            ],
            pageTitle: "Select",
            rule: "editor",
          },
        },
        {
          path: "/extensions/quill-editor",
          name: "extra-component-quill-editor",
          component: () =>
            import(
              "@/views/components/extra-components/quill-editor/QuillEditor.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extra Components" },
              { title: "Quill Editor", active: true },
            ],
            pageTitle: "Quill Editor",
            rule: "editor",
          },
        },
        {
          path: "/extensions/drag-and-drop",
          name: "extra-component-drag-and-drop",
          component: () =>
            import(
              "@/views/components/extra-components/drag-and-drop/DragAndDrop.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extra Components" },
              { title: "Drag & Drop", active: true },
            ],
            pageTitle: "Drag & Drop",
            rule: "editor",
          },
        },
        {
          path: "/extensions/datepicker",
          name: "extra-component-datepicker",
          component: () =>
            import(
              "@/views/components/extra-components/datepicker/Datepicker.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extra Components" },
              { title: "Datepicker", active: true },
            ],
            pageTitle: "Datepicker",
            rule: "editor",
          },
        },
        {
          path: "/extensions/datetime-picker",
          name: "extra-component-datetime-picker",
          component: () =>
            import(
              "@/views/components/extra-components/datetime-picker/DatetimePicker.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extra Components" },
              { title: "Datetime Picker", active: true },
            ],
            pageTitle: "Datetime Picker",
            rule: "editor",
          },
        },
        {
          path: "/extensions/access-control",
          name: "extra-component-access-control",
          component: () =>
            import(
              "@/views/components/extra-components/access-control/AccessControl.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Access Control", active: true },
            ],
            pageTitle: "Access Control",
            rule: "editor",
          },
        },
        {
          path: "/extensions/i18n",
          name: "extra-component-i18n",
          component: () =>
            import("@/views/components/extra-components/I18n.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "I18n", active: true },
            ],
            pageTitle: "I18n",
            rule: "editor",
          },
        },
        {
          path: "/extensions/carousel",
          name: "extra-component-carousel",
          component: () =>
            import("@/views/components/extra-components/carousel/Carousel.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Carousel", active: true },
            ],
            pageTitle: "Carousel",
            rule: "editor",
          },
        },
        {
          path: "/extensions/clipboard",
          name: "extra-component-clipboard",
          component: () =>
            import(
              "@/views/components/extra-components/clipboard/Clipboard.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Clipboard", active: true },
            ],
            pageTitle: "Clipboard",
            rule: "editor",
          },
        },
        {
          path: "/extensions/context-menu",
          name: "extra-component-context-menu",
          component: () =>
            import(
              "@/views/components/extra-components/context-menu/ContextMenu.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Context Menu", active: true },
            ],
            pageTitle: "Context Menu",
            rule: "editor",
          },
        },
        {
          path: "/extensions/star-ratings",
          name: "extra-component-star-ratings",
          component: () =>
            import(
              "@/views/components/extra-components/star-ratings/StarRatings.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Star Ratings", active: true },
            ],
            pageTitle: "Star Ratings",
            rule: "editor",
          },
        },
        {
          path: "/extensions/autocomplete",
          name: "extra-component-autocomplete",
          component: () =>
            import(
              "@/views/components/extra-components/autocomplete/Autocomplete.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Autocomplete", active: true },
            ],
            pageTitle: "Autocomplete",
            rule: "editor",
          },
        },
        {
          path: "/extensions/tree",
          name: "extra-component-tree",
          component: () =>
            import("@/views/components/extra-components/tree/Tree.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Tree", active: true },
            ],
            pageTitle: "Tree",
            rule: "editor",
          },
        },
        {
          path: "/import-export/import",
          name: "import-excel",
          component: () =>
            import(
              "@/views/components/extra-components/import-export/Import.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Import/Export" },
              { title: "Import", active: true },
            ],
            pageTitle: "Import Excel",
            rule: "editor",
          },
        },
        {
          path: "/import-export/export",
          name: "export-excel",
          component: () =>
            import(
              "@/views/components/extra-components/import-export/Export.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Import/Export" },
              { title: "Export", active: true },
            ],
            pageTitle: "Export Excel",
            rule: "editor",
          },
        },
        {
          path: "/import-export/export-selected",
          name: "export-excel-selected",
          component: () =>
            import(
              "@/views/components/extra-components/import-export/ExportSelected.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Import/Export" },
              { title: "Export Selected", active: true },
            ],
            pageTitle: "Export Excel",
            rule: "editor",
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/callback",
          name: "auth-callback",
          component: () => import("@/views/Callback.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/how-to",
          name: "how-to",
          component: () => import("@/views/pages/HowTo.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/login",
          name: "page-login",
          component: () => import("@/views/pages/login/Login.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/select-workspace",
          name: "select-workspace",
          component: () =>
            import("@/views/pages/select-organization/SelectOrganization.vue"),
          meta: {
            pageTitle: "Fresh Clinics | My Organisations",
            rule: "editor",
          },
        },
        {
          path: "/order-login",
          name: "page-order-login",
          component: () => import("@/views/pages/login/LoginOrder.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/existing-members",
          name: "page-existing-members",
          component: () => import("@/views/pages/login/ExistingMembers.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/join-fresh-clinics",
          name: "join-fresh-clinics",
          component: () => import("@/views/pages/login/JoinFreshClinics.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/register",
          name: "page-register",
          component: () => import("@/views/pages/register/Register.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/forgot-password",
          name: "page-forgot-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/set-password/:token",
          name: "user-forgot-password",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/checkTokenExpiry", payload)
              .then(() => {
                next();
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
          component: () => import("@/views/pages/setPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/verify-email/:token",
          name: "user-account-verification",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/verifyAccount", payload)
              .then(() => {
                next("/login");
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/onboarding/:token",
          name: "onboarding-verification",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/verifyAccount", payload)
              .then(() => {
                next("/login");
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/reset-password",
          name: "page-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/reset-password/:token",
          name: "user-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/success",
          name: "reset-password-success",
          component: () => import("@/views/pages/Success.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/lock-screen",
          name: "page-lock-screen",
          component: () => import("@/views/pages/LockScreen.vue"),
          meta: {
            rule: "editor",
          },
        },

        {
          path: "/pages/comingsoon",
          name: "page-coming-soon",
          component: () => import("@/views/pages/ComingSoon.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-419",
          name: "page-error-419",
          component: () => import("@/views/pages/Error419.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-403",
          name: "page-error-403",
          component: () => import("@/views/pages/Error403.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/maintenance",
          name: "page-maintenance",
          component: () => import("@/views/pages/Maintenance.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/privacy-policy/:slug",
          name: "PrivacyPolicy",
          component: () => import("@/views/pages/PrivacyPolicy.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/terms-and-conditions/:slug",
          name: "TermsAndConditions",
          component: () => import("@/views/pages/Terms.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/agreement/:slug",
          name: "DoctorAgreement",
          component: () => import("@/views/pages/DoctorAgreement.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/agreement/:slug",
          name: "ClinicAgreement",
          component: () => import("@/views/pages/ClinicAgreement.vue"),
          meta: {
            rule: "editor",
          },
        },

        {
          path: "/p/:sharedProductId",
          name: "sharedProduct",
          component: () => import("@/views/pages/SharedProduct.vue"),
          meta: {
            rule: "editor",
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found

    // {
    //   path: "/franchise",
    //   component: () => import("@/layouts/full-page/FullPage.vue"),
    //   meta: {
    //     rule: "editor"
    //   },
    //   children: [
    //     {
    //       path: "register",
    //       name: "FranchiseRegister",
    //       component: () =>
    //         import("@/views/franchise/pages/FranchiseRegister.vue"),
    //       meta: {
    //         pageTitle: "Franchise Register",
    //         rule: "editor"
    //       }
    //     }
    //   ]
    // },
    /******************* ROUTE FOR SuperAdmin PORTAL STARTED***********************/
    {
      path: "/super-admin",
      redirect: "/super-admin/dashboard",
      component: () => import("./layouts/main/Main.vue"),
      meta: {
        rule: "editor",
      },
      children: [
        {
          path: "dashboard",
          name: "SuperAdminDashboard",
          component: () => import("./views/superAdmin/Dashboard.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Dashboard",
            rule: "editor",
          },
        },
        {
          path: "clinic-dashboard",
          name: "SuperAdminClinicDashboard",
          component: () => import("./views/superAdmin/clinic-dashboard-v2/ClinicDashboard.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Clinic Dashboard",
            rule: "editor",
          },
        },
        {
          path: "clinic-dashboard-demo",
          name: "SuperAdminClinicDashboardDemo",
          component: () => import("./views/superAdmin/clinic-dashboard-demo/ClinicDashboard.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Demo Clinic Dashboard",
            rule: "editor",
          },
        },
        {
          path: "nurse-dashboard",
          name: "NurseDashboard",
          component: () => import("@/views/superAdmin/clinic-nurse-dashboard/ClinicNurseDashboard.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Nurse Dashboard",
            rule: "editor"
          },
        },
        {
          path: "community-portal",
          name: "CommunityPortalManagement",
          component: () =>
            import("@/views/superAdmin/community-portal/community-portal.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Community Portal Management",
            rule: "editor",
          },
        },
        {
          path: "community-portal/create",
          name: "SuperAdminCommunityCreate",
          component: () =>
            import("@/views/superAdmin/community-portal/create.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              {
                title: "Community Portal",
                url: "/super-admin/community-portal",
              },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Create",
            rule: "editor",
          },
        },
        {
          path: "community-portal/:id",
          name: "SuperAdminCommunityEdit",
          component: () =>
            import("@/views/superAdmin/community-portal/edit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              {
                title: "Community Portal",
                url: "/super-admin/community-portal",
              },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Edit",
            rule: "editor",
          },
        },

        {
          path: "my-account",
          name: "super-admin-my-account",
          component: () => import("@/views/superAdmin/myAccount.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Profile", active: true },
            ],
            pageTitle: "Fresh Clinics | My Account",
            rule: "editor",
          },
        },
        /*** Page Management route started ***/
        {
          path: "pages",
          component: () => import("./views/superAdmin/pages/CMSPage.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Pages | List",
            rule: "editor",
          },
        },
        {
          path: "pages/:id",
          name: "CMSPageDetails",
          component: () =>
            import("./views/superAdmin/pages/CMSPageDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Pages", url: "/super-admin/pages" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Pages | Details",
            rule: "editor",
          },
        },
        /** HUBSPOT SETTINGS*/
        {
          path: "hubspot-webhook-settings",
          name: "HubspotWebhookSettings",
          component: () => import("./views/superAdmin/hubspot/Settings.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/my-account" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Hubspot Webhook Settings",
            rule: "editor",
          },
        },
        {
          path: "hubspot-webhook-settings/create",
          name: "HubspotWebhookSettingsCreate",
          component: () =>
            import("./views/superAdmin/hubspot/CreateSetting.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/my-account" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Create Hubspot Webhook Settings",
            rule: "editor",
          },
        },
        /** ROUTES FOR THE CONSENT QUESTIONNAIRE */
        {
          path: "questionnaire",
          name: "ConsentQuestionnaire",
          component: () =>
            import("./views/superAdmin/questionnaire/CMSQuestionnaire.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Consent Questionnaire | list",
            rule: "editor",
          },
        },
        {
          path: "questionnaire/create",
          name: "QuestionnaireCreate",
          component: () =>
            import(
              "./views/superAdmin/questionnaire/CMSCreateQuestionnaire.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Questionnaire", url: "/super-admin/questionnaire" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Consent Questionnaire | Create",
            rule: "editor",
          },
        },
        {
          path: "questionnaire/:id",
          name: "CMSConsentQuestionnaire",
          component: () =>
            import(
              "./views/superAdmin/questionnaire/CMSQuestionnaireDetails.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              {
                title: "Consent Questionnaire",
                url: "/super-admin/questionnaire",
              },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Consent Questionnaire | Details",
            rule: "editor",
          },
        },
        /****SUPER ADMIN PUSH NOTIFICATION ROUTE STARTED****/
        {
          path: "push-notification",
          name: "SuperAdminPushNotification",
          component: () =>
            import("./views/components/push-notification/List.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Push Notification", active: true },
            ],
            pageTitle: "Fresh Clinics | Push Notification | List",
            rule: "editor",
          },
        },
        {
          path: "push-notification/send",
          name: "SuperAdminSendPushNotification",
          component: () =>
            import("./views/components/push-notification/Send.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              {
                title: "Push Notification",
                url: "/super-admin/push-notification",
              },
              { title: "Send", active: true },
            ],
            pageTitle: "Fresh Clinics | Push Notification | Send",
            rule: "editor",
          },
        },
        /****SUPER ADMIN PUSH NOTIFICATION ROUTE ENDED****/
        /****SUPER ADMIN EventBrite ROUTE STARTED****/
        {
          path: "events",
          name: "SuperAdminEvents",
          component: () =>
            import("./views/superAdmin/event-brite/List.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Event Brite | List",
            rule: "editor",
          },
        },
        /****SUPER ADMIN EventBrite ROUTE ENDED****/
        /****SUPER ADMIN Resource Hub - Json Settings ROUTE STARTED****/
        {
          path: "resource/json-settings",
          name: "SuperAdminResourceJsonSetting",
          component: () =>
            import("./views/superAdmin/resource-hub/JsonSetting.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Resource | Json Setting",
            rule: "editor",
          },
        },
        /****SUPER ADMIN Resource Hub - Json Settings ROUTE ENDED****/
        /****SUPER ADMIN DOCTOR AGGREGATED REPORT ROUTE STARTED****/
        {
          path: "doctor-aggregated-report",
          name: "SuperAdminDoctorAggregatedReport",
          component: () =>
            import("./views/components/doctor-aggregated-report/List.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Doctor Aggregated Report", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctor Aggregated Report | List",
            rule: "editor",
          },
        },
        {
          path: "doctor-aggregated-report/:invoiceId/details",
          name: "SuperAdminDoctorAggregatedReportDetails",
          component: () =>
            import("./views/components/doctor-aggregated-report/Detail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              {
                title: "Doctor Aggregated Report",
                url: "/super-admin/doctor-aggregated-report",
              },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctor Aggregated Report | Detail",
            rule: "editor",
          },
        },
        /****SUPER ADMIN DOCTOR AGGREGATED REPORT ROUTE ENDED****/
        /****SUPER ADMIN INVOICE ROUTE STARTED****/
        {
          path: "invoice",
          name: "SuperAdminInvoice",
          component: () => import("./views/components/invoice/List.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Invoice", active: true },
            ],
            pageTitle: "Fresh Clinics | Invoice | List",
            rule: "editor",
          },
        },
        /****SUPER ADMIN INVOICE ROUTE ENDED****/
        /****ADMIN PROFILE ROUTE STARTED****/
        {
          path: "profile",
          name: "admin-profile",
          component: () => import("@/views/pages/adminProfile.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Profile", active: true },
            ],
            pageTitle: "Fresh Clinics | Profile",
            rule: "editor",
          },
        },
        {
          path: "change-password",
          name: "SuperAdminChangePassword",
          component: () => import("@/views/pages/changePassword.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Change Password", active: true },
            ],
            pageTitle: "Fresh Clinics | Change Password",
            rule: "editor",
          },
        },
        {
          path: "success",
          name: "super-admin-change-password-success",
          component: () => import("@/views/pages/Success.vue"),
          meta: {
            rule: "editor",
          },
        },

        /****EMAIL TEMPLATE MANAGEMENT ROUTE STARTED****/
        {
          path: "email-templates",
          name: "email-template",
          component: () => import("@/views/superAdmin/email/EmailTemplate.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Email Templates | List",
            rule: "editor",
          },
        },
        {
          path: "email-templates/:id",
          name: "edit-email-template",
          component: () =>
            import("@/views/superAdmin/email/EditEmailTemplate.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Email Templates", url: "/super-admin/email-templates" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Email Templates | Edit",
            rule: "editor",
          },
        },

        /****PUSH NOTIFICATION TEMPLATE MANAGEMENT ROUTE STARTED****/
        {
          path: "push-notification-templates",
          name: "push-notification-template",
          component: () =>
            import(
              "@/views/superAdmin/push-notification-templates/PushNotificationTemplate.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Push Notification Templates | List",
            rule: "editor",
          },
        },
        {
          path: "push-notification-templates/:id",
          name: "edit-push-notification-template",
          component: () =>
            import(
              "@/views/superAdmin/push-notification-templates/EditPushNotificationTemplate.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              {
                title: "Push Notification Templates",
                url: "/super-admin/push-notification-templates",
              },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Push Notification Templates | Edit",
            rule: "editor",
          },
        },
        /**** ADMIN MANAGEMENT ROUTE STARTED****/
        {
          path: "admins",
          name: "admin-user",
          component: () => import("@/views/superAdmin/admin/AdminUser.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Admins | List",
            rule: "editor",
          },
        },
        {
          path: "admins/:id",
          name: "edit-admin-user",
          component: () => import("./views/superAdmin/admin/EditAdminUser.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Admins", url: "/super-admin/admins" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Admins | Edit",
            rule: "editor",
          },
        },
        {
          path: "create-admin",
          name: "create-admin-user",
          component: () =>
            import("./views/superAdmin/admin/CreateAdminUser.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Admins", url: "/super-admin/admins" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Admins | Create",
            rule: "editor",
          },
        },

        /**** APP USER MANAGEMENT ROUTE STARTED ****/
        {
          path: "app-user",
          name: "app-user",
          component: () => import("@/views/superAdmin/appUser/AppUser.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/super-admin/dashboard" }],
            pageTitle: "Fresh Clinics | App User List",
            rule: "editor",
          },
        },
        {
          path: "app-user/:id",
          name: "edit-app-user",
          component: () => import("./views/superAdmin/appUser/EditAppUser.vue"),
          meta: {
            pageTitle: "Fresh Clinics | App User Details",
            rule: "editor",
          },
        },
        {
          path: "create-app-user",
          name: "create-app-user",
          component: () =>
            import("./views/superAdmin/appUser/CreateAppUser.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Create User",
            rule: "editor",
          },
        },

        /****CLINIC MANAGEMENT ROUTE STARTED****/
        {
          path: "clinics",
          name: "SuperAdminClinic",
          component: () => import("@/views/superAdmin/clinics/clinics.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | List",
            rule: "editor",
          },
        },
        // {
        //   path: "clinics/create",
        //   name: "SuperAdminClinicCreate",
        //   component: () =>
        //     import("@/views/superAdmin/clinics/createClinic.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/super-admin/dashboard" },
        //       { title: "Clinics", url: "/super-admin/clinics" },
        //       { title: "Create", active: true },
        //     ],
        //     pageTitle: "Fresh Clinics | Clinics | Create",
        //     rule: "editor",
        //   },
        // },
        {
          path: "clinics/onboard",
          name: "SuperAdminClinicOnboard",
          component: () =>
            import("@/views/superAdmin/clinics/onboardClinic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Clinics", url: "/super-admin/clinics" },
              { title: "Onboard", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Onboard",
            rule: "editor",
          },
        },
        {
          path: "clinics/:clinicId/edit",
          name: "SuperAdminClinicEdit",
          component: () => import("@/views/superAdmin/clinics/clinicsEdit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Clinics", url: "/super-admin/clinics" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Edit",
            rule: "editor",
          },
        },
        {
          path: "clinics/:clinicId/treatment-history",
          name: "SuperAdminClinicTreatmentHistory",
          component: () =>
            import("@/views/clinic/history/TreatmentHistory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Clinics", url: "/super-admin/clinics" },
              { title: "Treatment History", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Treatment History",
            rule: "editor",
          },
        },
        {
          path: "clinics/:clinicId/detail",
          name: "SuperAdminClinicDetail",
          component: () =>
            import("@/views/superAdmin/clinics/ClinicDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Clinics", url: "/super-admin/clinics" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Detail",
            rule: "editor",
          },
        },

        /****MONTHLY COMPLIANCE ROUTE STARTED****/
        {
          path: "compliance/:clinicId/review",
          name: "superAdminComplianceReview",
          component: () => import("./views/superAdmin/compliance/Review.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Review", active: true },
            ],
            pageTitle: "Fresh Clinics | Compliance | Review",
            rule: "editor",
          },
        },
        {
          path: "compliance/:clinicId/reschedule",
          name: "superAdminComplianceReschedule",
          component: () =>
            import("./views/superAdmin/compliance/rescheduleBooking.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Reschedule", active: true },
            ],
            pageTitle: "Fresh Clinics | Compliance | Reschedule",
            rule: "editor",
          },
        },
        {
          path: "compliance",
          name: "superAdminComplianceList",
          component: () =>
            import("./views/superAdmin/compliance/Compliance.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Compliance", active: true },
            ],
            pageTitle: "Fresh Clinics | Compliance | Review",
            rule: "editor",
          },
        },
        /**DRUGBOOK ROUTE STARTED */
        {
          path: "drugbook",
          name: "superAdminDrugbookList",
          component: () => import("./views/superAdmin/drugbook/Drugbook.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Drugbook", active: true },
            ],
            pageTitle: "Fresh Clinics | Drugbook",
            rule: "editor",
          },
        },
        /**INVENTORY ROUTE STARTED */
        {
          path: "inventory",
          name: "superAdminInventory",
          component: () => import("./views/superAdmin/inventory/Inventory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Inventory", active: true },
            ],
            pageTitle: "Fresh Clinics | Inventory",
            rule: "editor",
          },
        },
        /**INVENTORY ROUTE ENDED */
        /****FRANCHISE MANAGEMENT ROUTE STARTED****/
        {
          path: "franchises",
          name: "franchise-management",
          component: () =>
            import("./views/superAdmin/franchise/franchiseList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | List",
            rule: "editor",
          },
        },
        {
          path: "franchises/create",
          name: "franchise-management-create",
          component: () =>
            import("./views/superAdmin/franchise/createFranchise.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Franchises", url: "/super-admin/franchises" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | Create",
            rule: "editor",
          },
        },
        {
          path: "franchises/:franchiseId",
          name: "franchise-management-edit",
          component: () =>
            import("./views/superAdmin/franchise/viewFranchise.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Franchises", url: "/super-admin/franchises" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | Edit",
            rule: "editor",
          },
        },
        {
          path: "franchises/:franchiseId/doctors/create",
          name: "franchise-management-doctors-create",
          component: () =>
            import("./views/superAdmin/franchise/createFranchiseDoctor.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Franchises", url: "/super-admin/franchises" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | Edit",
            rule: "editor",
          },
        },
        {
          path: "franchises/:franchiseId/nurses/create",
          name: "superadmin-franchise-management-nurses-create",
          component: () =>
            import("./views/superAdmin/franchise/createFranchiseNurse.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Franchises", url: "/super-admin/franchises" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | Edit",
            rule: "editor",
          },
        },
        {
          path: "franchises/:franchiseId/clinics/create",
          name: "superadmin-franchise-clinics-create",
          component: () =>
            import("./views/superAdmin/franchise/createFranchiseClinic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Franchises", url: "/super-admin/franchises" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | Edit",
            rule: "editor",
          },
        },
        {
          path: "franchises/:franchiseId/associated-clinics",
          name: "franchise-management-associated-clinic-list-view",
          component: () =>
            import("./views/superAdmin/franchise/viewFranchiseClinicList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Franchises", url: "/super-admin/franchises" },
              { title: "Clinics", url: "/super-admin/clinics" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | Associated Clinics | List",
            rule: "editor",
          },
        },

        /********patient management route started********/
        // {
        //   path: "patients",
        //   name: "PatientManagement",
        //   component: () => import("@/views/superAdmin/patient/PatientList.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "superAdmin/dashboard" },
        //       { title: "List", active: true },
        //     ],
        //     pageTitle: "Fresh Clinics | Patients | List",
        //     rule: "editor",
        //   },
        // },
        // {
        //   path: "patients/:patientId",
        //   name: "SuperAdminPatientDetail",
        //   component: () => import("@/views/admin/patient/PatientDetails.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/super-admin/dashboard" },
        //       { title: "Patients", url: "/super-admin/patients" },
        //       { title: "Detail", active: true },
        //     ],
        //     pageTitle: "Fresh Clinics | Patients | Detail",
        //     rule: "editor",
        //   },
        // },
        /********super admin product management route started********/

        {
          path: "products",
          name: "ProductManagement",
          component: () => import("@/views/superAdmin/product/List.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Products | List",
            rule: "editor",
          },
        },
        {
          path: "products/add",
          name: "AddProduct",
          component: () => import("./views/superAdmin/product/AddProduct.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Product list", url: "/super-admin/products" },
              { title: "Add", active: true },
            ],
            pageTitle: "Add Product",
            rule: "editor",
          },
        },
        {
          path: "products/edit/:productId",
          name: "SuperAdminEditProduct",
          component: () => import("./views/superAdmin/product/EditProduct.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Product list", url: "/super-admin/products" },
              { title: "Edit Product", active: true },
            ],
            pageTitle: "Edit Product",
            rule: "editor",
          },
        },
        /********super admin product management route ended********/
        /********super admin applicable area route started********/

        {
          path: "applicable-areas",
          name: "ApplicableAreaManagement",
          component: () => import("@/views/superAdmin/applicableArea/List.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Applicable Area | List",
            rule: "editor",
          },
        },
        {
          path: "applicable-areas/add",
          name: "AddApplicableArea",
          component: () =>
            import("./views/superAdmin/applicableArea/AddApplicableArea.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              {
                title: "Applicable Area list",
                url: "/super-admin/applicable-areas",
              },
              { title: "Add", active: true },
            ],
            pageTitle: "Add Applicable Area",
            rule: "editor",
          },
        },
        {
          path: "applicable-areas/edit/:id",
          name: "SuperAdminEditApplicableArea",
          component: () =>
            import("./views/superAdmin/applicableArea/EditApplicableArea.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              {
                title: "Applicable Area list",
                url: "/super-admin/applicable-areas",
              },
              { title: "Edit Applicable Area", active: true },
            ],
            pageTitle: "Edit Applicable Area",
            rule: "editor",
          },
        },
        /********super admin applicable area route ended********/

        /*** super admin invoices routes started ***/
        {
          path: "invoice/upcoming-invoices",
          name: "UpcomingInvoiceList",
          component: () => import("@/views/superAdmin/invoice/listInvoice.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Upcoming Invoices", active: true },
            ],
            pageTitle: "Fresh Clinics | Upcoming Invoices | List",
            rule: "editor",
          },
        },
        {
          path: "invoice/upcoming-invoices/details/:id",
          name: "UpcomingInvoiceDetails",
          component: () =>
            import("@/views/superAdmin/invoice/viewInvoiceTreatment.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Invoice List", url: "/super-admin/invoice/list" },
              { title: "Manage Treatment", active: true },
            ],
            pageTitle: "Fresh Clinics | Upcoming Invoices | Details",
            rule: "editor",
          },
        },
        /*** super admin invoices routes ends here ***/
        /*** super admin past invoices routes started ***/
        {
          path: "invoice/list",
          name: "pastInvoiceList",
          component: () =>
            import("@/views/superAdmin/invoice/listPastInvoices.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Invoices", active: true },
            ],
            pageTitle: "Fresh Clinics | Invoices | List",
            rule: "editor",
          },
        },
        /*** super admin past invoices routes ends here ***/
        /*** super admin store homepage management route starts here ***/
        {
          path: "store/json-settings",
          name: "StoreHomepageManagement",
          component: () =>
            import("@/views/superAdmin/store/homepage/updateHomepage.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Store Homepage",
            rule: "editor",
          },
        },
        {
          path: "store/update/promotions-page",
          name: "StorePromotionsPageManagement",
          component: () =>
            import("@/views/superAdmin/store/pages/updatePromotions"),
          meta: {
            pageTitle: "Fresh Clinics | Update | Promotions",
            rule: "editor",
          },
        },
        {
          path: "store/update/shopHomepage",
          name: "StorePromotionsPageManagement",
          component: () =>
            import("@/views/superAdmin/store/pages/updateHomepage"),
          meta: {
            pageTitle: "Fresh Clinics | Update | Homepage",
            rule: "editor",
          },
        },
        /*** super admin store homepage management route ends here ***/

        /*** super admin store shop management route start here ***/
        {
          path: "store/shop",
          name: "SuperAdminOrderCenter",
          component: () =>
            import("@/views/org-owner/ecommerce/OrderCenter.vue"),
          meta: {
            // breadcrumb: [
            //   { title: "Home", url: "/org-owner/shop" },
            //   { title: "Shop", active: true },
            // ],
            pageTitle: "Fresh Clinics | Shop",
            rule: "editor",
          },
        },
        {
          path: "store/shop/category/:slug",
          name: "SuperAdminOrderCenterCategoryPage",
          component: () =>
            import("@/views/org-owner/ecommerce/Category.vue"),
          meta: {
            // breadcrumb: [
            //   { title: "Home", url: "/org-owner/shop" },
            //   { title: "Shop", active: true },
            // ],
            pageTitle: "Fresh Clinics | Shop",
            rule: "editor",
          },
        },
        {
          path: "store/shop/search",
          name: "SuperAdminOrderCenterSearch",
          component: () =>
            import("@/views/org-owner/ecommerce/SearchResult.vue"),
          meta: {
            // breadcrumb: [
            //   { title: "Home", url: "/org-owner/shop" },
            //   { title: "Shop", active: true },
            // ],
            pageTitle: "Fresh Clinics | Shop",
            rule: "editor",
          },
        },
        {
          path: "store/shop/brands/:slug",
          name: "SuperAdminOrderCenterBrandPage",
          component: () =>
            import("@/views/org-owner/ecommerce/Brand.vue"),
          meta: {
            // breadcrumb: [
            //   { title: "Home", url: "/org-owner/shop" },
            //   { title: "Shop", active: true },
            // ],
            pageTitle: "Fresh Clinics | Shop",
            rule: "editor",
          },
        },
        {
          path: "store/product/:id",
          name: "SuperAdminProductDetail",
          component: () =>
            import("@/views/org-owner/ecommerce/ProductDetail.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Product Detail",
            rule: "editor",
          },
        },
        {
          path: "store/checkout",
          name: "SuperAdminProductCheckout",
          component: () =>
            import("@/views/org-owner/ecommerce/ClinicCheckout.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Shop", url: "/super-admin/store/shop" },
              { title: "Product Checkout", active: true },
            ],
            pageTitle: "Fresh Clinics | Product Checkout",
            rule: "editor",
          },
        },
        {
          path: "shop/promotions",
          name: "SuperAdminPromotionsPage",
          component: () =>
            import("@/views/org-owner/ecommerce/ShopPromotions.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Shop",
            rule: "editor",
          },
        },
        /*** super admin store homepage management route ends here ***/

        /*** super admin brand management route started ***/
        {
          path: "store/brands",
          name: "BrandManagement",
          component: () =>
            import("@/views/superAdmin/store/brand/list.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Brands", active: true },
            ],
            pageTitle: "Fresh Clinics | Brands | List",
            rule: "editor",
          },
        },
        {
          path: "brands/add",
          name: "AddBrand",
          component: () =>
            import("./views/superAdmin/store/brand/add.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Brands List", url: "/super-admin/store/brands" },
              { title: "Add", active: true },
            ],
            pageTitle: "Add Brand",
            rule: "editor",
          },
        },
        {
          path: "brands/edit/:brandId",
          name: "EditBrand",
          component: () =>
            import("./views/superAdmin/store/brand/edit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Brands List", url: "/super-admin/store/brands" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Edit Brand",
            rule: "editor",
          },
        },
        {
          path: "brands/preview/:slug",
          name: "SuperAdminCustomBrandPreviewPage",
          component: () =>
            import("@/views/org-owner/ecommerce/Brand.vue"),
          meta: {
            // breadcrumb: [
            //   { title: "Home", url: "/org-owner/shop" },
            //   { title: "Shop", active: true },
            // ],
            pageTitle: "Fresh Clinics | Shop",
            rule: "editor",
          },
        },
        /*** super admin brand management route ended ***/
        /********super admin category management route started********/

        {
          path: "store/categories",
          name: "CategoryManagement",
          component: () =>
            import("@/views/superAdmin/store/category/listCategory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Categories", active: true },
            ],
            pageTitle: "Fresh Clinics | Categories | List",
            rule: "editor",
          },
        },
        {
          path: "categories/add",
          name: "AddCategory",
          component: () =>
            import("./views/superAdmin/store/category/addCategory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Category list", url: "/super-admin/store/categories" },
              { title: "Add", active: true },
            ],
            pageTitle: "Add Category",
            rule: "editor",
          },
        },
        {
          path: "categories/edit/:categoryId",
          name: "EditCategory",
          component: () =>
            import("./views/superAdmin/store/category/editCategory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Category list", url: "/super-admin/store/categories" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Edit Category",
            rule: "editor",
          },
        },
        /********super admin category management route ended********/
        /********super admin discount route started********/
        {
          path: "store/vouchers",
          name: "VoucherManagement",
          component: () =>
            import("@/views/superAdmin/voucher/ListVoucher.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Vouchers", active: true },
            ],
            pageTitle: "Fresh Clinics | Vouchers | List",
            rule: "editor",
          },
        },
        {
          path: "store/discounts",
          name: "DiscountManagement",
          component: () =>
            import("@/views/superAdmin/discount/ListDiscount.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Discounts", active: true },
            ],
            pageTitle: "Fresh Clinics | Discounts | List",
            rule: "editor",
          },
        },
        {
          path: "store/discounts/add",
          name: "AddDiscount",
          component: () =>
            import("./views/superAdmin/discount/AddDiscount.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Discount list", url: "/super-admin/store/discounts" },
              { title: "Add", active: true },
            ],
            pageTitle: "Add Discount",
            rule: "editor",
          },
        },
        {
          path: "store/discounts/edit/:discountId",
          name: "EditDiscount",
          component: () =>
            import("./views/superAdmin/discount/EditDiscount.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Discount list", url: "/super-admin/store/discounts" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Edit Discount",
            rule: "editor",
          },
        },
        /********super admin discount route ended********/


        /********super admin alert route started********/
        {
          path: "store/alert",
          name: "AlertManagement",
          component: () =>
            import("@/views/superAdmin/alert/Alerts.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Alert", active: true },
            ],
            pageTitle: "Fresh Clinics | Alert | List",
            rule: "editor",
          },
        },
        {
          path: "store/alert/create",
          name: "CreateAlert",
          component: () =>
            import("@/views/components/alerts/createAlert.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Alert", url: "/super-admin/store/alert" },
              { title: "Create Alert", active: true },
            ],
            pageTitle: "Fresh Clinics | Alert | Create",
            rule: "editor",
          },
        },
        {
          path: "store/alert/:alertId",
          name: "SuperAdminAlertDetails",
          component: () =>
            import("@/views/components/alerts/editAlert.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Alert", url: "/super-admin/store/alert" },
              { title: "Edit Alert", active: true },
            ],
            pageTitle: "Fresh Clinics | Alert | Create",
            rule: "editor",
          },
        },
        {
          path: "store/alert/preview/:alertId",
          name: "AlertPreview",
          component: () =>
            import("@/views/components/alerts/preview.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Alert", url: "/super-admin/store/alert" },
              { title: "Preview Alert", active: true },
            ],
            pageTitle: "Fresh Clinics | Alert | Create",
            rule: "editor",
          },
        },
        /********super admin alert route ended********/

        {
          path: "nurses/:nurseId/patients/:patientId/treatment/:checkinId",
          name: "AdminPatientTreatmentDetail",
          component: () =>
            import("@/views/admin/patient/PatientTreatmentDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Patients", url: "/super-admin/patients" },
              { title: "Treatment Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Patients | Treatment Detail",
            rule: "editor",
          },
        },
        {
          path: "patients/:patientId/treatment/:checkinId",
          name: "SuperAdminPatientTreatmentDetail",
          component: () =>
            import("@/views/superAdmin/patient/PatientTreatmentDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Patients", url: "/super-admin/patients" },
              { title: "Treatment Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Patients | Treatment Detail",
            rule: "editor",
          },
        },
        /* DOCTORS MANAGEMENT STARTED */
        {
          path: "doctors",
          name: "SuperAdminDoctor",
          component: () => import("@/views/superAdmin/doctors/doctorList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | List",
            rule: "editor",
          },
        },
        {
          path: "doctors/create",
          name: "SuperAdminDoctorCreate",
          component: () =>
            import("@/views/superAdmin/doctors/createDoctor.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Doctors", url: "/super-admin/doctors" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Create ",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId/edit",
          name: "SuperAdminDoctorEdit",
          component: () => import("@/views/superAdmin/doctors/doctorEdit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Doctors", url: "/super-admin/doctors" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Edit",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId/detail",
          name: "SuperAdminDoctorDetail",
          component: () => import("@/views/general-component/UserDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Doctors", url: "/super-admin/doctors" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Detail",
            rule: "editor",
          },
        },
        {
          path: "nurses/:nurseId/detail",
          name: "SuperAdminNurseDetailView",
          component: () => import("@/views/general-component/UserDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Nurses", url: "/super-admin/nurses" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurse | Detail",
            rule: "editor",
          },
        },
        {
          path: "doctors/:userId/profile-update-review",
          name: "SuperAdminDoctorProfileReview",
          component: () =>
            import("@/views/general-component/updateProfileReview.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Doctors", url: "/super-admin/doctors" },
              { title: "Updated Profile Review", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Updated Profile Review",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId",
          name: "SuperAdminDoctorView",
          component: () =>
            import("@/views/superAdmin/doctors/doctorDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Doctors", url: "/super-admin/doctors" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Detail",
            rule: "editor",
          },
        },
        /*BASIC ROUTE MANAGEMENT*/
        {
          path: "basic-users",
          name: "SuperAdminBasicUsers",
          component: () =>
            import("./views/superAdmin/basic-user/BasicUsersList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Basic Users | list",
            rule: "editor",
          },
        },
        {
          path: "basic-users/:basicUserId",
          name: "SuperAdminBasicDetails",
          component: () =>
            import("./views/superAdmin/basic-user/BasicDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Basic User", url: "/super-admin/basic-users" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Basic | Edit",
            rule: "editor",
          },
        },
        {
          path: "organization/owner/:basicUserId",
          name: "SuperAdminOrganizationOwnerDetails",
          component: () =>
            import("./views/superAdmin/basic-user/BasicDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Organizations", url: "/super-admin/organizations" },
              { title: "Owner" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Basic | Edit",
            rule: "editor",
          },
        },
        {
          path: "basic-users/create",
          name: "SuperAdminCreateBasicUser",
          component: () =>
            import("./views/superAdmin/user/CreateUserBasic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Basic User", url: "/super-admin/basic-users" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Basic | Create",
            rule: "editor",
          },
        },
        /*BASIC USER ROUTE MANAGEMENT*/
        /*ORGANIZATION ROUTE MANAGEMENT*/
        {
          path: "organizations",
          name: "SuperAdminOrganization",
          component: () =>
            import("@/views/superAdmin/organizations/Organizations.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | organization | List",
            rule: "editor",
          },
        },
        {
          path: "organizations/create",
          name: "SuperAdminOrganizationCreate",
          component: () =>
            import("./views/superAdmin/organizations/OrganizationCreate.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Organizations", url: "/super-admin/organizations" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Organization | Create ",
            rule: "editor",
          },
        },
        {
          path: "organizations/:organizationId",
          name: "SuperAdminOrganizationDetails",
          component: () =>
            import("./views/superAdmin/organizations/organizationDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Organizations", url: "/super-admin/organizations" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Organizations | Details ",
            rule: "editor",
          },
        },
        {
          path: "organizations/orders/:orderId",
          name: "SuperAdminOrganizationOrderDetail",
          component: () =>
            import("@/views/clinic/ecommerce/order/OrderDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Organizations", url: "/super-admin/organizations" },
              { title: "Order Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Organizations | Order Detail ",
            rule: "editor",
          },
        },
        {
          path: "organizations/:organizationId/clinics/:clinicId/edit",
          name: "SuperAdminOrganizationClinicEdit",
          component: () => import("@/views/superAdmin/clinics/clinicsEdit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Organizations", url: "/super-admin/organizations" },
              { title: "Clinic" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Edit",
            rule: "editor",
          },
        },
        {
          path: "organizations/:organizationId/clinics/create",
          name: "SuperAdminOrganizationClinicEdit",
          component: () =>
            import("@/views/organization/clinic/createClinic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Organizations", url: "/super-admin/organizations" },
              { title: "Create Clinic", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Edit",
            rule: "editor",
          },
        },
        {
          path: "clinics/create/:doctorClinicId?",
          name: "SuperAdminClinicCreate",
          component: () =>
            import("@/views/organization/clinic/createClinic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Clinics", url: "/super-admin/clinics" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Create",
            rule: "editor",
          },
        },
        /* DOCTOR CLINIC ROUTE MANAGEMENT */
        {
          path: "doctor-clinics",
          name: "SuperAdminDoctorClinics",
          component: () =>
            import("./views/superAdmin/doctor-clinics/franchiseList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctor Clinics | list",
            rule: "editor",
          },
        },
        {
          path: "doctor-clinics/create",
          name: "SuperAdminDoctorClinicsCreate",
          component: () =>
            import("./views/superAdmin/doctor-clinics/createFranchise.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Doctor Clinics", url: "/super-admin/doctor-clinics" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctor Clinics | list",
            rule: "editor",
          },
        },
        {
          path: "doctor-clinics/:franchiseId",
          name: "SuperAdminDoctorClinicsEdit",
          component: () =>
            import("./views/superAdmin/doctor-clinics/viewFranchise.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Doctor Clinics", url: "/super-admin/doctor-clinics" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctor Clinics | list",
            rule: "editor",
          },
        },
        {
          path: "doctor-clinics/:franchiseId/clinics/create",
          name: "SuperAdminDoctorClinicsCreateClinics",
          component: () =>
            import(
              "./views/superAdmin/doctor-clinics/createFranchiseClinic.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Doctor Clinics", url: "/super-admin/doctor-clinics" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctor Clinics | Edit",
            rule: "editor",
          },
        },

        /* Derma Specialist ROUTE MANAGEMENT */
        {
          path: "derma-specialist",
          name: "SuperAdminDermaSpecialist",
          component: () =>
            import("./views/superAdmin/derma-specialist/franchiseList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | list",
            rule: "editor",
          },
        },
        {
          path: "derma-specialist/create",
          name: "SuperAdminDermaSpecialistCreate",
          component: () =>
            import("./views/superAdmin/derma-specialist/createFranchise.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              {
                title: "Derma Specialist",
                url: "/super-admin/derma-specialist",
              },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | list",
            rule: "editor",
          },
        },
        {
          path: "derma-specialist/:franchiseId",
          name: "SuperAdminDermaSpecialistEdit",
          component: () =>
            import("./views/superAdmin/derma-specialist/viewFranchise.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              {
                title: "Derma Specialist",
                url: "/super-admin/derma-specialist",
              },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | list",
            rule: "editor",
          },
        },
        {
          path: "derma-specialist/:franchiseId/clinics/create",
          name: "SuperAdminDermaSpecialistCreateClinics",
          component: () =>
            import(
              "./views/superAdmin/derma-specialist/createFranchiseClinic.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              {
                title: "Derma Specialist",
                url: "/super-admin/derma-specialist",
              },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | Edit",
            rule: "editor",
          },
        },
        /* NURSE MANAGEMENT ROUTE STARTED*/
        {
          path: "nurses",
          name: "SuperAdminNurses",
          component: () => import("./views/superAdmin/nurses/NurseList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | list",
            rule: "editor",
          },
        },
        {
          path: "nurses/create",
          name: "SuperAdminNurseCreate",
          component: () => import("./views/superAdmin/nurses/NurseCreate.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Nurses", url: "/super-admin/nurses" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Create ",
            rule: "editor",
          },
        },
        {
          path: "nurses/onboard",
          name: "SuperAdminNurseOnboard",
          component: () => import("@/views/superAdmin/nurses/NurseOnboard.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Nurses", url: "/super-admin/nurses" },
              { title: "Onboard", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Onboard",
            rule: "editor",
          },
        },
        {
          path: "nurses/:nurseId",
          name: "SuperAdminNurseDetails",
          component: () => import("./views/superAdmin/nurses/NurseDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Nurses", url: "/super-admin/nurses" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Edit",
            rule: "editor",
          },
        },
        {
          path: "nurses/:userId/profile-update-review",
          name: "SuperAdminNurseProfileReview",
          component: () =>
            import("@/views/general-component/updateProfileReview.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Nurses", url: "/super-admin/nurses" },
              { title: "Updated Profile Review", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Updated Profile Review",
            rule: "editor",
          },
        },
        {
          path: "nurses/:nurseId/patient/:patientId/treatment/:checkinId",
          name: "SuperAdminNurseTreatmentDetail",
          component: () =>
            import("./views/superAdmin/nurses/TreatmentHistoryDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Nurses", url: "/super-admin/nurses" },
              { title: "Treatment Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Treatment Detail",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId/patient/:patientId/treatment/:checkinId",
          name: "SuperAdminDoctorTreatmentDetail",
          component: () =>
            import("./views/superAdmin/doctors/TreatmentHistoryDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Doctors", url: "/super-admin/doctors" },
              { title: "Treatment Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Treatment Detail",
            rule: "editor",
          },
        },
        /*Assign Clinic ROUTE STARTED*/
        {
          path: "assign-clinic",
          name: "assign-clinic",
          component: () => import("@/views/franchise/clinics/assignClinic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Assign Clinic",
            rule: "editor",
          },
        },

        /*PAIR DEVICE ROUTE STARTED*/
        {
          path: "pair-device",
          name: "pairDevice",
          component: () => import("@/views/franchise/clinics/pairDevices.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Pair Device",
            rule: "editor",
          },
        },
        /*SAFETY VIDEO ROUTE STARTED*/
        {
          path: "safety-video",
          name: "safetyVideo",
          component: () =>
            import("@/views/superAdmin/safetyVideo/safetyVideo.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/super-admin/dashboard" }],
            pageTitle: "Fresh Clinics | Safety Video",
            rule: "editor",
          },
        },
        {
          path: "notifications",
          name: "superAdminNotification",
          component: () => import("@/views/superAdmin/NotificationList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Notifications | List",
            rule: "editor",
          },
        },
        {
          path: "settings",
          name: "superAdminSettings",
          component: () => import("@/views/components/AdminSettings.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Settings", active: true },
            ],
            pageTitle: "Fresh Clinics | Settings",
            rule: "editor",
          },
        },
        {
          path: "doctor-missedcall-settings",
          name: "doctorMissedCallsSettings",
          component: () =>
            import("@/views/components/DoctorMissedCallSettings.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Doctor Missed Calls Settings", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctor Missed Calls Settigns",
            rule: "editor",
          },
        },
        {
          path: "roles",
          name: "roles",
          component: () =>
            import("./views/components/roles-permissions/Role.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | User Roles",
            rule: "editor",
            //'authRequired': true
          },
        },
        {
          path: "roles/:roleId/assign-permission",
          component: () =>
            import(
              "./views/components/roles-permissions/AssignPermissionToRole.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Assign Permissions To Role", active: true },
            ],
            pageTitle: "Fresh Clinics | Roles | Assign Permission",
            rule: "editor",
          },
        },
        {
          path: "transaction-logs",
          name: "superAdminTransactionLogs",
          component: () => import("@/views/components/Logs/TransactionLog.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Transaction Logs", active: true },
            ],
            pageTitle: "Fresh Clinics | Transaction Logs",
            rule: "editor",
          },
        },
        {
          path: "store/products",
          name: "superAdminStoreProducts",
          component: () =>
            import("@/views/components/WebStore/products/Products.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Products", active: true },
            ],
            pageTitle: "Fresh Clinics | Products",
            rule: "editor",
          },
        },
        {
          path: "store/products/create",
          name: "superAdminStoreProductsCreate",
          component: () =>
            import("@/views/components/WebStore/products/CreateProducts.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Product List", url: "/super-admin/store/products" },
              { title: "Create Product", active: true },
            ],
            pageTitle: "Fresh Clinics | Create Product",
            rule: "editor",
          },
        },
        {
          path: "store/products/:id/edit",
          name: "SuperAdminStoreProductsEdit",
          component: () =>
            import("@/views/components/WebStore/products/EditProducts.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Product List", url: "/super-admin/store/products" },
              { title: "Edit Product", active: true },
            ],
            pageTitle: "Fresh Clinics | Edit Product",
            rule: "editor",
          },
        },
        {
          path: "store/products/:id/edit-variants",
          name: "SuperAdminStoreProductsEditVariants",
          component: () =>
            import(
              "@/views/components/WebStore/products/EditProductVariants.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Product List", url: "/super-admin/store/products" },
              { title: "Edit Product Variants", active: true },
            ],
            pageTitle: "Fresh Clinics | Edit Product Variants",
            rule: "editor",
          },
        },

        /****super admin suppliers management route started********/

        {
          path: "store/suppliers",
          name: "superAdminStoreSuppliers",
          component: () =>
            import("@/views/superAdmin/store/supplier/ListSuppliers.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Suppliers", active: true },
            ],
            pageTitle: "Fresh Clinics | Suppliers | List",
            rule: "editor",
          },
        },
        {
          path: "store/suppliers/add",
          name: "superAdminStoreAddSuppliers",
          component: () =>
            import("./views/superAdmin/store/supplier/CreateSupplier.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Supplier list", url: "/super-admin/store/suppliers" },
              { title: "Add", active: true },
            ],
            pageTitle: "Add Supplier",
            rule: "editor",
          },
        },
        {
          path: "store/suppliers/view/:supplierId",
          name: "superAdminStoreViewSuppliers",
          component: () =>
            import("./views/superAdmin/store/supplier/ViewSupplier"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Supplier list", url: "/super-admin/store/suppliers" },
              { title: "Details", active: true },
            ],
            pageTitle: "View Details",
            rule: "editor",
          },
        },
        {
          path: "store/suppliers/edit/:supplierId",
          name: "superAdminStoreEditSuppliers",
          component: () =>
            import("./views/superAdmin/store/supplier/EditSupplier"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Supplier list", url: "/super-admin/store/suppliers" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Edit Supplier",
            rule: "editor",
          },
        },
        {
          path: "store/suppliers/:supplierId/order/:orderId",
          name: "superAdminStoreViewSuppliersOrderDetail",
          component: () =>
            import("./views/superAdmin/store/supplier/ViewOrder"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Supplier list", url: "/super-admin/store/suppliers" },
              // { title: "Suppliers Details", url: "/super-admin/store/suppliers/view/:supplierId" },
              { title: "Order Details", active: true },
            ],
            pageTitle: "View Order Details",
            rule: "editor",
          },
        },
        {
          path: "store/suppliers/find-nearby-clinics",
          name: "superAdminStoreFindNearbyClinics",
          component: () =>
            import(
              "./views/components/WebStore/suppliers/FindNearbyClinics.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Supplier list", url: "/super-admin/store/suppliers" },
              { title: "Find Nearby Clinics", active: true },
            ],
            pageTitle: "Fresh Clinics | Suppliers | Find Nearby Clinics",
            rule: "editor",
          },
        },
        /********super admin suppliers management route ended********/

        // super admin store order routes
        {
          path: "store/orders",
          name: "superAdminStoreOrders",
          component: () => import("./views/superAdmin/store/order/ListOrders"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Orders", active: true },
            ],
            pageTitle: "Fresh Clinics | Orders | List",
            rule: "editor",
          },
        },
        {
          path: "store/orders/view/:orderId",
          name: "superAdminStoreViewOrder",
          component: () => import("./views/superAdmin/store/order/ViewOrder"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Orders", url: "/super-admin/store/orders" },
              { title: "Details", active: true },
            ],
            pageTitle: "View Details",
            rule: "editor",
          },
        },
        {
          path: "store/orders/create",
          name: "superAdminStoreCreateOrder",
          component: () => import("./views/superAdmin/store/order/CreateOrder"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Orders", url: "/super-admin/store/orders" },
              { title: "Create", active: true },
            ],
            pageTitle: "Create Order",
            rule: "editor",
          },
        },
        /*** Treatment Types route started ***/
        {
          path: "treatment-types",
          name: "superAdminTreatmentTypes",
          component: () => import("./views/components/treatmentTypes/List"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Treatment Types", active: true },
            ],
            pageTitle: "List",
            rule: "editor",
          },
        },
        {
          path: "treatment-types/:id/edit",
          name: "superAdminTreatmentTypesEdit",
          component: () => import("./views/components/treatmentTypes/Edit"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Treatment Types", url: "/super-admin/treatment-types" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Edit",
            rule: "editor",
          },
        },
        // {
        //   path: "nurses/:patientId/treatment/:checkinId",
        //   name: "SuperAdminNurseTreatmentDetail",
        //   component: () =>
        //     import("./views/superAdmin/nurses/TreatmentHistoryDetail.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "super-admin/dashboard" },
        //       { title: "Nurses", url: "/super-admin/nurses" },
        //       { title: "Treatment Detail", active: true }
        //     ],
        //     pageTitle: "Fresh Clinics | Nurses | Treatment Detail",
        //     rule: "editor"
        //   }
        // }

        /* USER MANAGEMENT ROUTE STARTED*/
        {
          path: "create-user",
          name: "SuperAdminCreateUser",
          component: () => import("./views/superAdmin/user/CreateUser.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Create User", active: true },
            ],
            pageTitle: "Fresh Clinics | User | Create",
            rule: "editor",
          },
        },
        /* Clinic Dashboard ROUTE STARTED*/
        {
          path: "clinic-dashboard",
          name: "SuperAdminClinicDashboard",
          component: () => import("./views/superAdmin/clinic-dashboard-v2/ClinicDashboard.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Clinic Dashboard", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinic Dashboard",
            rule: "editor",
          },
        },
        /* INSIGHTS ROUTE STARTED*/
        {
          path: "insights",
          name: "SuperAdminInsightsTools",
          component: () => import("./views/superAdmin/insights/Insights.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Insights", active: true },
            ],
            pageTitle: "Fresh Clinics | Insights Tools",
            rule: "editor",
          },
        },
        {
          path: "insights/nurses",
          name: "SuperAdminInsightsNurses",
          component: () => import("./views/superAdmin/insights/Nurses.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Insights", url: "/super-admin/insights" },
              { title: "Nurses", active: true },
            ],
            pageTitle: "Fresh Clinics | Insights",
            rule: "editor",
          },
        },
        {
          path: "insights/patient-trends",
          name: "SuperAdminInsightsPatients",
          component: () =>
            import("./views/superAdmin/insights/PatientTrends.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Insights", url: "/super-admin/insights" },
              { title: "Patients", active: true },
            ],
            pageTitle: "Fresh Clinics | Insights",
            rule: "editor",
          },
        },
        {
          path: "insights/products",
          name: "SuperAdminInsightsProducts",
          component: () => import("./views/superAdmin/insights/Products.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Insights", url: "/super-admin/insights" },
              { title: "Products", active: true },
            ],
            pageTitle: "Fresh Clinics | Insights",
            rule: "editor",
          },
        },
        {
          path: "insights/clinics",
          name: "SuperAdminInsightClinics",
          component: () => import("./views/superAdmin/insights/Clinics.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Insights", url: "/super-admin/insights" },
              { title: "Clinics", active: true },
            ],
            pageTitle: "Fresh Clinics | Insight - Clinics",
            rule: "editor",
          },
        },
        {
          path: "insights/treatment-area",
          name: "SuperAdminInsightTreatmentAreas",
          component: () =>
            import("./views/superAdmin/insights/TreatmentAreas.vue"),
            meta: {
              breadcrumb: [
                { title: "Home", url: "/super-admin/dashboard" },
                { title: "Insights", url: "/super-admin/insights" },
                { title: "Treatment Area", active: true },
              ],
              pageTitle: "Fresh Clinics | Insight - Treatment Area",
              rule: "editor",
            },
            children: [
              {
                path: "single-vs-multiple",
                name: "SuperAdminInsightSingle",
                component: () =>
                  import("./views/superAdmin/insights/SingleVSMultiple.vue"),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    { title: "Single VS Multiple Treatment", active: true },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Single Vs. Multiple Treatments",
                  rule: "editor",
                },
                alias: "/",
              },
              {
                path: "all/treatments-per-treatment-area",
                name: "SuperAdminAverageTreatmentsPerTreatmentAreaAll",
                component: () =>
                  import(
                    "./views/superAdmin/insights/TreatmentsPerTreatmentArea.vue"
                  ),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    {
                      title: "Number of Treatments per Treatment Area (All)",
                      active: true,
                    },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Treatment",
                  rule: "editor",
                },
              },
              {
                path: "toxin/treatments-per-treatment-area",
                name: "SuperAdminAverageTreatmentsPerTreatmentAreaToxin",
                component: () =>
                  import(
                    "./views/superAdmin/insights/TreatmentsPerTreatmentArea.vue"
                  ),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    {
                      title: "Number of Treatments per Treatment Area (Toxin)",
                      active: true,
                    },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Treatment",
                  rule: "editor",
                },
              },
              {
                path: "filler/treatments-per-treatment-area",
                name: "SuperAdminAverageTreatmentsPerTreatmentAreaFiller",
                component: () =>
                  import(
                    "./views/superAdmin/insights/TreatmentsPerTreatmentArea.vue"
                  ),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    {
                      title: "Number of Treatments per Treatment Area (Filler)",
                      active: true,
                    },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Treatment",
                  rule: "editor",
                },
              },
              {
                path: "toxin/average-treatment-by-gender-and-age",
                name: "SuperAdminAverageTreatmentByGenderAndAge",
                component: () =>
                  import(
                    "./views/superAdmin/insights/AverageTreatmentByGenderAndAge.vue"
                  ),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    {
                      title: "Average Treatment By Gender And Age (Toxin)",
                      active: true,
                    },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Treatment",
                  rule: "editor",
                },
              },
              {
                path: "toxin/average-treatment-by-age",
                name: "SuperAdminAverageTreatmentByAge",
                component: () =>
                  import("./views/superAdmin/insights/AverageTreatmentByAge.vue"),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    {
                      title: "Average Treatment By Age (Toxin)",
                      active: true,
                    },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Treatment",
                  rule: "editor",
                },
              },
              {
                path: "all/average-treatment-by-area",
                name: "SuperAdminAverageTreatmentByAreaAll",
                component: () =>
                  import(
                    "./views/superAdmin/insights/AverageTreatmentByArea.vue"
                  ),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    { title: "Average Treatment By Area (All)", active: true },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Treatment",
                  rule: "editor",
                },
              },
              {
                path: "filler/average-treatment-by-area",
                name: "SuperAdminAverageTreatmentByAreaFiller",
                component: () =>
                  import(
                    "./views/superAdmin/insights/AverageTreatmentByArea.vue"
                  ),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    { title: "Average Treatment By Area (Filler)", active: true },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Treatment",
                  rule: "editor",
                },
              },
              {
                path: "toxin/average-treatment-by-area",
                name: "SuperAdminAverageTreatmentByAreaToxin",
                component: () =>
                  import(
                    "./views/superAdmin/insights/AverageTreatmentByArea.vue"
                  ),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    { title: "Average Treatment By Area (Toxin)", active: true },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Treatment",
                  rule: "editor",
                },
              },
              {
                path: "filler/average-treatment-by-gender-and-age",
                name: "SuperAdminAverageTreatmentByGenderAndAgeFiller",
                component: () =>
                  import(
                    "./views/superAdmin/insights/AverageTreatmentByGenderAndAge.vue"
                  ),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    {
                      title: "Average Treatment By Gender And Age (Filler)",
                      active: true,
                    },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Treatment",
                  rule: "editor",
                },
              },
              {
                path: "all/average-treatment-by-age",
                name: "SuperAdminAverageTreatmentByAgeAll",
                component: () =>
                  import(
                    "./views/superAdmin/insights/AverageTreatmentByAge.vue"
                  ),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    {
                      title: "Average Treatment By Age (All)",
                      active: true,
                    },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Treatment",
                  rule: "editor",
                },
              },
              {
                path: "filler/average-treatment-by-age",
                name: "SuperAdminAverageTreatmentByAgeFiller",
                component: () =>
                  import(
                    "./views/superAdmin/insights/AverageTreatmentByAge.vue"
                  ),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    {
                      title: "Average Treatment By Age (Filler)",
                      active: true,
                    },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Treatment",
                  rule: "editor",
                },
              },
              {
                path: "all/treatment-by-nurse",
                name: "SuperAdminInsightsTreatmentByNurseAll",
                component: () =>
                  import(
                    "./views/superAdmin/insights/TreatmentAreaByNurse.vue"
                  ),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    {
                      title: "Treatment Area by Nurse (All)",
                      active: true,
                    },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Treatment",
                  rule: "editor",
                },
              },
              {
                path: "filler/treatment-by-nurse",
                name: "SuperAdminInsightsTreatmentByNurseFiller",
                component: () =>
                  import(
                    "./views/superAdmin/insights/TreatmentAreaByNurse.vue"
                  ),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    {
                      title: "Treatment Area by Nurse",
                      active: true,
                    },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Treatment",
                  rule: "editor",
                },
              },
              {
                path: "toxin/treatment-by-nurse",
                name: "SuperAdminInsightsTreatmentByNurse",
                component: () =>
                  import(
                    "./views/superAdmin/insights/TreatmentAreaByNurse.vue"
                  ),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/super-admin/dashboard" },
                    { title: "Insights", url: "/super-admin/insights" },
                    {
                      title: "Treatment Area by Nurse",
                      active: true,
                    },
                  ],
                  pageTitle: "Fresh Clinics | Insight - Treatment",
                  rule: "editor",
                },
              },
            ],
        },
        {
          path: "insights/appointments",
          name: "SuperAdminInsightAppointments",
          component: () =>
            import("./views/superAdmin/insights/Appointments.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/super-admin/dashboard" },
              { title: "Insights", url: "/super-admin/insights" },
              { title: "Appointments", active: true },
            ],
            pageTitle: "Fresh Clinics | Insight - Appointments",
            rule: "editor",
          },
        },
      ],
    },

    /******************* ROUTE FOR SuperAdmin PORTAL ENDED***********************/


    /******************* ROUTE FOR ORG OWNER PORTAL START***********************/
    ...orgOwnerRoutes,
    /******************* ROUTE FOR ORG OWNER PORTAL ENDED***********************/

    /******************* ROUTE FOR FRANCHISE PORTAL STARTED***********************/
    {
      path: "/franchise",
      redirect: "/franchise/notice-board",
      component: () => import("./layouts/main/FranchiseMain.vue"),
      meta: {
        rule: "editor",
      },
      children: [
        {
          path: "notice-board",
          name: "notice-board",
          component: () => import("@/views/basic/Dashboard.vue"),
          meta: {
            pageTitle: "Fresh Clinics | My Organisations",
            rule: "editor",
          },
        },
        {
          path: "events",
          name: "events",
          component: () => import("@/views/basic/Events.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Events",
            rule: "editor",
          },
        },
        {
          path: "resources",
          name: "resources",
          component: () => import("@/views/basic/Resources.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Resources",
            rule: "editor",
          },
        },
        {
          path: "dashboard",
          name: "FranchiseDashboard",
          component: () => import("@/views/franchise/dashboard/dashboard.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Dashboard",
            rule: "editor",
          },
        },
        {
          path: "change-password",
          name: "FranchiseChangePassword",
          component: () => import("@/views/pages/changePassword.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Change Password",
            rule: "editor",
          },
        },
        {
          path: "success",
          name: "franchise-change-password-success",
          component: () => import("@/views/pages/Success.vue"),
          meta: {
            rule: "editor",
          },
        },
        /*PROFILE ROUTE STARTED*/
        {
          path: "profile",
          name: "franchise-profile",
          component: () => import("@/views/franchise/FranchiseProfile.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Profile",
            rule: "editor",
          },
        },
        /* SUB-FRANCHISE MANAGEMENT STARTED */
        {
          path: "admin",
          name: "SubFranchise",
          component: () =>
            import("@/views/franchise/subFranchise/subFranchise.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/franchise/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Admin | List",
            rule: "editor",
          },
        },
        {
          path: "admin/create",
          name: "SubFranchiseCreate",
          component: () =>
            import("@/views/franchise/subFranchise/addSubFranchise.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/franchise/dashboard" },
              { title: "Admin", url: "/franchise/admin" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Admin | Create",
            rule: "editor",
          },
        },
        {
          path: "admin/:franchiseId/edit",
          name: "FranchiseAdminEdit",
          component: () =>
            import("@/views/franchise/subFranchise/editFranchise.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/franchise/dashboard" },
              { title: "Admin", url: "/franchise/admin" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Admin | Edit",
            rule: "editor",
          },
        },
        {
          path: "admin/:franchiseId/details",
          name: "FranchiseAdminView",
          component: () =>
            import("@/views/franchise/subFranchise/SubFranchiseUserDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/franchise/dashboard" },
              { title: "Admin", url: "/franchise/admin" },
              { title: "Details", active: true },
            ],
            pageTitle: "Fresh Clinics | Admin | Details",
            rule: "editor",
          },
        },
        {
          path: "admin/:clinicId/detail",
          name: "SubFranchiseClinicDetail",
          component: () => import("@/views/franchise/clinics/ClinicDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/franchise/dashboard" },
              { title: "Admin", url: "/franchise/admin" },
              { title: "Details", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinic Detail",
            rule: "editor",
          },
        },
        /* CLINICS MANAGEMENT STARTED */
        {
          path: "clinics",
          name: "FranchiseClinic",
          component: () => import("@/views/franchise/clinics/clinics.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | List",
            rule: "editor",
          },
        },
        {
          path: "clinics/create",
          name: "FranchiseClinicCreate",
          component: () => import("@/views/franchise/clinics/createClinic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "Clinics", url: "/franchise/clinics" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Create",
            rule: "editor",
          },
        },
        {
          path: "clinics/:clinicId/edit",
          name: "FranchiseClinicEdit",
          component: () => import("@/views/franchise/clinics/clinicsEdit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "Clinics", url: "/franchise/clinics" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Edit",
            rule: "editor",
          },
        },
        {
          path: "clinics/:clinicId/treatment-history",
          name: "FranchiseClinicTreatmentHistory",
          component: () =>
            import("@/views/clinic/history/TreatmentHistory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/franchise/dashboard" },
              { title: "Clinics", url: "/franchise/clinics" },
              { title: "Treatment History", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Treatment History",
            rule: "editor",
          },
        },
        {
          path: "clinics/:clinicId/detail",
          name: "FranchiseClinicDetail",
          component: () => import("@/views/franchise/clinics/ClinicDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "Clinics", url: "/franchise/clinics" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Detail",
            rule: "editor",
          },
        },

        /* DOCTORS MANAGEMENT STARTED */
        {
          path: "doctors",
          name: "FranchiseDoctor",
          component: () => import("@/views/franchise/doctors/doctorList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | List",
            rule: "editor",
          },
        },
        {
          path: "doctors/create",
          name: "FranchiseDoctorCreate",
          component: () => import("@/views/franchise/doctors/createDoctor.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "Doctors", url: "/franchise/doctors" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Create",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId/edit",
          name: "FranchiseDoctorEdit",
          component: () => import("@/views/franchise/doctors/doctorEdit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "Doctors", url: "/franchise/doctors" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Edit",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId",
          name: "FranchiseDoctorView",
          component: () =>
            import("@/views/franchise/doctors/doctorDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "Doctors", url: "/franchise/doctors" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Detail",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId/detail",
          name: "FranchiseDoctorDetailView",
          component: () => import("@/views/general-component/UserDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/franchise/dashboard" },
              { title: "Doctors", url: "/franchise/doctors" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctor | Detail",
            rule: "editor",
          },
        },
        /* NURSE MANAGEMENT ROUTE STARTED*/
        {
          path: "nurses",
          name: "Nurses",
          component: () => import("./views/franchise/nurses/NurseList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | list",
            rule: "editor",
          },
        },
        {
          path: "nurses/:nurseId/detail",
          name: "FranchiseNurseDetailView",
          component: () => import("@/views/general-component/UserDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/franchise/dashboard" },
              { title: "Nurses", url: "/franchise/nurses" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurse | Detail",
            rule: "editor",
          },
        },
        {
          path: "nurses/create",
          name: "FranchiseNurseCreate",
          component: () => import("./views/franchise/nurses/NurseCreate.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "Nurses", url: "/franchise/nurses" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Create",
            rule: "editor",
          },
        },
        {
          path: "nurses/:nurseId",
          name: "FranchiseNurseDetails",
          component: () => import("./views/franchise/nurses/NurseDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "Nurses", url: "/franchise/nurses" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Edit",
            rule: "editor",
          },
        },
        {
          path: "nurses/:nurseId/patients/:patientId/treatment/:checkinId",
          name: "FranchiseNurseTreatmentDetail",
          component: () =>
            import("./views/franchise/nurses/TreatmentHistoryDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "Nurses", url: "/franchise/nurses" },
              { title: "Treatment Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Treatment Detail",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId/patients/:patientId/treatment/:checkinId",
          name: "FranchiseDoctorTreatmentDetail",
          component: () =>
            import("./views/franchise/doctors/TreatmentHistoryDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "Doctors", url: "/franchise/doctors" },
              { title: "Treatment Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Treatment Detail",
            rule: "editor",
          },
        },
        /* PATIENT MANAGEMENT STARTED */
        {
          path: "patients",
          name: "Patient",
          component: () => import("@/views/franchise/patient/PatientList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Patients | list",
            rule: "editor",
          },
        },
        {
          path: "patients/:patientId",
          name: "FranchisePatientDetail",
          component: () =>
            import("@/views/franchise/patient/PatientDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "Patients", url: "/franchise/patients" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Patients | Detail",
            rule: "editor",
          },
        },
        {
          path: "patients/:patientId/treatment/:checkinId",
          name: "FranchisePatientTreatmentDetail",
          component: () =>
            import("@/views/franchise/patient/PatientTreatmentDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "Patients", url: "/franchise/patients" },
              { title: "Treatment Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Patients | Treatment Detail",
            rule: "editor",
          },
        },
        /* Clinic Invoices for franchise */
        {
          path: "invoices",
          name: "FranchiseInvoices",
          component: () => import("@/views/franchise/invoices/List.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Invoices | list",
            rule: "editor",
          },
        },

        /*Assign Clinic ROUTE STARTED*/
        {
          path: "assign-clinic",
          name: "assign-clinic",
          component: () => import("@/views/franchise/clinics/assignClinic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Assign Clinic",
            rule: "editor",
          },
        },
        /*PAIR DEVICE ROUTE STARTED*/
        {
          path: "pair-device",
          name: "pairDevice",
          component: () => import("@/views/franchise/clinics/pairDevices.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "franchise/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Pair Device",
            rule: "editor",
          },
        },
        /** Franchis Settings **/
        {
          path: "settings/products-list",
          name: "Franchise Products List",
          component: () =>
            import("@/views/franchise/settings/ProductsList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/franchise/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Settings | Products List",
            rule: "editor",
          },
        },
        {
          path: "settings/treatment-areas",
          name: "Franchise Treatment Areas",
          component: () =>
            import("@/views/franchise/settings/TreatmentArea.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/franchise/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Settings | Treatment Areas",
            rule: "editor",
          },
        },
      ],
    },
    /******************* ROUTE FOR FRANCHISE PORTAL ENDED***********************/


    /******************* ROUTE FOR Doctor PORTAL START***********************/
    ...doctorRoutes,
    /******************* ROUTE FOR Doctor PORTAL ENDED***********************/
    /******************* ROUTE FOR Supplier PORTAL START***********************/
    ...supplierRoutes,
    /******************* ROUTE FOR Supplier PORTAL ENDED***********************/
    /********** ROUTE FOR DOCTORCLINIC PORTAL STARTED**************/
    {
      path: "/doctor-clinic",
      redirect: "/doctor-clinic/shop",
      component: () =>
        import("@/layouts/main/doctor-clinic/DoctorClinicMain.vue"),
      meta: {
        rule: "editor",
      },
      children: [
        {
          path: "notice-board",
          name: "notice-board",
          component: () => import("@/views/basic/Dashboard.vue"),
          meta: {
            pageTitle: "Fresh Clinics | My Organisations",
            rule: "editor",
          },
        },
        // {
        //   path: "events",
        //   name: "events",
        //   component: () => import("@/views/basic/Events.vue"),
        //   meta: {
        //     pageTitle: "Fresh Clinics | Events",
        //     rule: "editor"
        //   }
        // },
        // {
        //   path: "resources",
        //   name: "resources",
        //   component: () => import("@/views/basic/Resources.vue"),
        //   meta: {
        //     pageTitle: "Fresh Clinics | Resources",
        //     rule: "editor"
        //   }
        // },
        {
          path: "shop",
          name: "DoctorClinicOrderCenter",
          component: () =>
            import("@/views/doctor-clinic/ecommerce/OrderCenter.vue"),
          meta: {
            // breadcrumb: [
            //   { title: "Home", url: "/doctor-clinic/shop" },
            //   { title: "Shop", active: true },
            // ],
            pageTitle: "Fresh Clinics | Shop",
            rule: "editor",
          },
        },
        {
          path: "addCardDetails",
          name: "DoctorClinicAddCard",
          component: () => import("@/views/clinic/ClinicAddCard.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Add Card", active: true },
            ],
            pageTitle: "Fresh Clinics | Add Card",
            rule: "editor",
          },
        },
        {
          path: "success",
          name: "clinic-change-password-success",
          component: () => import("@/views/pages/Success.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "settings/products-list",
          name: "Clinic Products List",
          component: () => import("@/views/clinic/settings/ProductsList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Settings | Products List",
            rule: "editor",
          },
        },
        {
          path: "settings/treatment-areas",
          name: "Clinic Treatment Areas",
          component: () => import("@/views/clinic/settings/TreatmentArea.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Settings | Treatment Areas",
            rule: "editor",
          },
        },
        {
          path: "settings",
          name: "DoctorClinicSettings",
          component: () =>
            import("@/views/doctor-clinic/settings/DoctorClinicSettings.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Settings", active: true },
            ],
            pageTitle: "Fresh Clinics | Settings",
            rule: "editor",
          },
        },
        {
          path: "product/:id",
          name: "DoctorClinicProductDetail",
          component: () => import("@/views/clinic/ecommerce/ProductDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Shop", url: "/doctor-clinic/shop" },
              { title: "Product Details", active: true },
            ],
            pageTitle: "Fresh Clinics | Product Detail",
            rule: "editor",
          },
        },
        {
          path: "checkout",
          name: "DoctorClinicProductCheckout",
          component: () =>
            import("@/views/doctor-clinic/ecommerce/Checkout.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Shop", url: "/doctor-clinic/shop" },
              { title: "Product Checkout", active: true },
            ],
            pageTitle: "Fresh Clinics | Product Checkout",
            rule: "editor",
          },
        },
        {
          path: "order-complete",
          name: "DoctorClinicOrderComplete",
          component: () =>
            import("@/views/clinic/ecommerce/ClinicOrderComplete.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Shop", url: "/doctor-clinic/shop" },
              { title: "Order Complete", active: true },
            ],
            pageTitle: "Fresh Clinics | Order Complete",
            rule: "editor",
          },
        },
        {
          path: "orders",
          name: "DoctorOrderList",
          component: () =>
            import("@/views/clinic/ecommerce/order/OrderList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Orders", active: true },
            ],
            pageTitle: "Fresh Clinics | Orders",
            rule: "editor",
          },
        },
        {
          path: "orders/:orderId",
          name: "DoctorClinicOrderDetail",
          component: () =>
            import("@/views/clinic/ecommerce/order/OrderDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Orders", url: "/doctor-clinic/orders" },
              { title: "Order Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Order Detail",
            rule: "editor",
          },
        },
        {
          path: "setup-bank-detail",
          name: "DoctorClinicBankDetails",
          component: () =>
            import("@/views/clinic/ClinicPaymentMethodSetup.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Profile", url: "/doctor-clinic/profile" },
              { title: "Payment Details", active: true },
            ],
            pageTitle: "Fresh Clinics | Bank Details",
            rule: "editor",
          },
        },
        {
          path: "profile",
          name: "DoctorProfile",
          component: () => import("@/views/clinic/ClinicProfile.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Profile", active: true },
            ],
            pageTitle: "Fresh Clinics | Profile",
            rule: "editor",
          },
        },
        {
          path: "change-password",
          name: "DoctorClinicChangePassword",
          component: () => import("@/views/pages/changePassword.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dotor-clinic/shop" },
              { title: "Change Password", active: true },
            ],
            pageTitle: "Fresh Clinics | Change Password",
            rule: "editor",
          },
        },
        {
          path: "orders",
          name: "OrderList",
          component: () =>
            import("@/views/clinic/ecommerce/order/OrderList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Orders", active: true },
            ],
            pageTitle: "Fresh Clinics | Orders",
            rule: "editor",
          },
        },
      ],
    },
    /******************* ROUTE FOR DOCTORCLINIC PROFILE COMPLETE ENDED***********************/
    /********** ROUTE FOR CLINIC PORTAL STARTED**************/
    {
      path: "/clinic",
      redirect: "/clinic/notice-board",
      component: () => import("@/layouts/main/clinic/ClinicMain.vue"),
      meta: {
        rule: "editor",
      },
      children: [
        {
          path: "notice-board",
          name: "notice-board",
          component: () => import("@/views/basic/Dashboard.vue"),
          meta: {
            pageTitle: "Fresh Clinics | My Organisations",
            rule: "editor",
          },
        },
        {
          path: "events",
          name: "events",
          component: () => import("@/views/basic/Events.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Events",
            rule: "editor",
          },
        },
        {
          path: "resources",
          name: "resources",
          component: () => import("@/views/basic/Resources.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Resources",
            rule: "editor",
          },
        },
        {
          path: "dashboard",
          name: "ClinicDashboard",
          component: () => import("@/views/clinic/ClinicDashboard.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Dashboard",
            rule: "editor",
          },
        },
        {
          path: "change-password",
          name: "ClinicChangePassword",
          component: () => import("@/views/pages/changePassword.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "Change Password", active: true },
            ],
            pageTitle: "Fresh Clinics | Change Password",
            rule: "editor",
          },
        },
        {
          path: "addCardDetails",
          name: "ClinicAddCard",
          component: () => import("@/views/clinic/ClinicAddCard.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "Add Card", active: true },
            ],
            pageTitle: "Fresh Clinics | Add Card",
            rule: "editor",
          },
        },
        {
          path: "success",
          name: "clinic-change-password-success",
          component: () => import("@/views/pages/Success.vue"),
          meta: {
            rule: "editor",
          },
        },
        /* PATIENT MANAGEMENT STARTED */
        {
          path: "patients",
          name: "ClinicPatient",
          component: () => import("@/views/clinic/patient/PatientList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Patients | list",
            rule: "editor",
          },
        },
        {
          path: "patients/:patientId",
          name: "ClinicPatientDetail",
          component: () => import("@/views/clinic/patient/PatientDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "Patients", url: "/clinic/patients" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Patients | Detail",
            rule: "editor",
          },
        },
        {
          path: "patients/:patientId/treatment/:checkinId",
          name: "ClinicPatientTreatmentDetail",
          component: () =>
            import("@/views/clinic/patient/PatientTreatmentDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "Patients", url: "/clinic/patients" },
              { title: "Treatment Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Patients | Treatment Detail",
            rule: "editor",
          },
        },

        /* DOCTORS MANAGEMENT STARTED */
        {
          path: "doctors",
          name: "ClinicDoctor",
          component: () => import("@/views/clinic/doctors/doctorList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | List",
            rule: "editor",
          },
        },
        {
          path: "doctors/create",
          name: "ClinicDoctorCreate",
          component: () => import("@/views/clinic/doctors/createDoctor.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "Doctors", url: "/clinic/doctors" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Create",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId/edit",
          name: "ClinicDoctorEdit",
          component: () => import("@/views/clinic/doctors/doctorEdit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "Doctors", url: "/clinic/doctors" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Edit",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId",
          name: "ClinicDoctorView",
          component: () => import("@/views/clinic/doctors/doctorDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "Doctors", url: "/clinic/doctors" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Detail",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId/detail",
          name: "ClinicDoctorDetailView",
          component: () => import("@/views/general-component/UserDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "Doctors", url: "/clinic/doctors" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctor | Detail",
            rule: "editor",
          },
        },
        /* NURSE MANAGEMENT ROUTE STARTED*/
        {
          path: "nurses",
          name: "ClinicNurse",
          component: () => import("./views/clinic/nurses/NurseList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | List",
            rule: "editor",
          },
        },
        {
          path: "nurses/create",
          name: "ClinicNurseCreate",
          component: () => import("./views/clinic/nurses/NurseCreate.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "Nurses", url: "/clinic/nurses" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Create",
            rule: "editor",
          },
        },
        {
          path: "nurses/:nurseId",
          name: "ClinicNurseDetails",
          component: () => import("./views/clinic/nurses/NurseDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "Nurses", url: "/clinic/nurses" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Edit",
            rule: "editor",
          },
        },
        {
          path: "nurses/:nurseId/patients/:patientId/treatment/:checkinId",
          name: "ClinicNurseTreatmentDetailView",
          component: () =>
            import("./views/clinic/nurses/TreatmentHistoryDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "Nurses", url: "/clinic/nurses" },
              { title: "Treatment Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Treatment Detail",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId/patients/:patientId/treatment/:checkinId",
          name: "ClinicDoctorTreatmentDetailView",
          component: () =>
            import("./views/clinic/doctors/TreatmentHistoryDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "Doctors", url: "/clinic/doctors" },
              { title: "Treatment Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Treatment Detail",
            rule: "editor",
          },
        },
        {
          path: "nurses/:patientId/treatment/:checkinId",
          name: "ClinicNurseTreatmentDetail",
          component: () =>
            import("./views/clinic/nurses/TreatmentHistoryDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "Nurses", url: "/clinic/nurses" },
              { title: "Treatment Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Treatment Detail",
            rule: "editor",
          },
        },
        {
          path: "nurses/:nurseId/detail",
          name: "ClinicNurseDetailView",
          component: () => import("@/views/general-component/UserDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "Nurses", url: "/clinic/nurses" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Detail",
            rule: "editor",
          },
        },
        /*PROFILE ROUTE STARTED*/
        {
          path: "profile",
          name: "clinic-profile",
          component: () => import("@/views/clinic/ClinicProfile.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "Profile", active: true },
            ],
            pageTitle: "Fresh Clinics | Profile",
            rule: "editor",
          },
        },
        /*Assign Clinic ROUTE STARTED*/
        {
          path: "assign-clinic",
          name: "assign-clinic",
          component: () => import("@/views/franchise/clinics/assignClinic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Assign Clinic",
            rule: "editor",
          },
        },
        /*PAIR DEVICE ROUTE STARTED*/
        {
          path: "pair-device",
          name: "pairDevice",
          component: () => import("@/views/franchise/clinics/pairDevices.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Pair Device",
            rule: "editor",
          },
        },
        // /*CUSTOM CONSENT ROUTE STARTED*/
        // {
        //   path: "custom-consent",
        //   name: "customConsent",
        //   component: () =>
        //     import("@/views/franchise/clinics/customConsent.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/clinic/custom-consent" },
        //       { title: "Consent Form", active: true },
        //     ],
        //     pageTitle: "Fresh Clinics | Consent Form",
        //     rule: "editor",
        //   },
        // },

        // /*CREATE CUSTOM CONSENT ROUTE STARTED*/
        // {
        //   path: "custom-consent/create",
        //   name: "createCustomConsent",
        //   component: () =>
        //     import("@/views/franchise/clinics/createCustomConsent.vue"),
        //   props: (route) => ({
        //     ...route.params,
        //   }),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/clinic/dashboard" },
        //       { title: "Consent List", url: "/clinic/custom-consent" },
        //       { title: "Create consent question", active: true },
        //     ],
        //     pageTitle: "Fresh Clinics | Consent Form",
        //     rule: "editor",
        //   },
        // },

        /**Clinic Treatment History Route Started**/
        {
          path: "treatment-history",
          name: "ClinicTreatmentHistory",
          component: () =>
            import("@/views/clinic/history/TreatmentHistory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "clinic/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Treatment History | List",
            rule: "editor",
          },
        },
        /** Sub clinic access management route started */
        /****Clinic INVOICE ROUTE STARTED****/
        {
          path: "invoice",
          name: "ClinicInvoice",
          component: () => import("./views/components/invoice/List.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "Invoice", active: true },
            ],
            pageTitle: "Fresh Clinics | Invoice | List",
            rule: "editor",
          },
        },
        {
          path: "admin",
          name: "SubClinicManagement",
          component: () => import("@/views/clinic/subClinic/SubClinic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Admin | List",
            rule: "editor",
          },
        },
        {
          path: "admin/create",
          name: "SubClinicCreate",
          component: () => import("@/views/clinic/subClinic/addSubClinic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "Admin", url: "/clinic/admin" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Admin | Create",
            rule: "editor",
          },
        },
        {
          path: "admin/:clinicId/edit",
          name: "SubClinicEdit",
          component: () => import("@/views/clinic/subClinic/editSubClinic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "Admin", url: "/clinic/admin" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Admin | Edit",
            rule: "editor",
          },
        },
        {
          path: "admin/:clinicId/detail",
          name: "SubClinicView",
          component: () => import("@/views/clinic/subClinic/viewSubClinic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "Admin", url: "/clinic/admin" },
              { title: "Details", active: true },
            ],
            pageTitle: "Fresh Clinics | Admin | Details",
            rule: "editor",
          },
        },
        /**Clinic Treatment History Route Ended**/
        /** Clinic Bank Detail Setup **/
        {
          path: "setup-bank-detail",
          name: "ClinicBankDetails",
          component: () =>
            import("@/views/clinic/ClinicPaymentMethodSetup.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "Profile", url: "/clinic/profile" },
              { title: "Payment Details", active: true },
            ],
            pageTitle: "Fresh Clinics | Bank Details",
            rule: "editor",
          },
        },
        /** Clinic Settings **/
        {
          path: "settings/doctor-rate",
          name: "ClinicDoctorRate",
          component: () => import("@/views/clinic/settings/DoctorRate.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/clinic/dashboard" }],
            pageTitle: "Fresh Clinics | Doctor Rate",
            rule: "editor",
          },
        },
        {
          path: "settings/products-list",
          name: "Clinic Products List",
          component: () => import("@/views/clinic/settings/ProductsList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Settings | Products List",
            rule: "editor",
          },
        },
        {
          path: "settings/treatment-areas",
          name: "Clinic Treatment Areas",
          component: () => import("@/views/clinic/settings/TreatmentArea.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Settings | Treatment Areas",
            rule: "editor",
          },
        },
        /** Clinic Order Center **/
        {
          path: "shop",
          name: "ClinicOrderCenter",
          component: () => import("@/views/clinic/ecommerce/OrderCenter.vue"),
          meta: {
            // breadcrumb: [
            //   { title: "Home", url: "/clinic/dashboard" },
            //   { title: "Shop", active: true },
            // ],
            pageTitle: "Fresh Clinics | Shop",
            rule: "editor",
          },
        },
        {
          path: "coming-soon",
          name: "ClinicOrderCenter",
          component: () => import("@/views/clinic/ClinicShopComingSoon.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "Shop", active: true },
            ],
            pageTitle: "Fresh Clinics | Shop",
            rule: "editor",
          },
        },
        {
          path: "coming-soon",
          name: "ClinicOrderCenter",
          component: () => import("@/views/clinic/ClinicShopComingSoon.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "Shop", active: true },
            ],
            pageTitle: "Fresh Clinics | Shop",
            rule: "editor",
          },
        },
        {
          path: "product/:id",
          name: "ClinicProductDetail",
          component: () => import("@/views/clinic/ecommerce/ProductDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "Shop", url: "/clinic/shop" },
              { title: "Product Details", active: true },
            ],
            pageTitle: "Fresh Clinics | Product Detail",
            rule: "editor",
          },
        },
        {
          path: "checkout",
          name: "ClinicProductCheckout",
          component: () =>
            import("@/views/clinic/ecommerce/ClinicCheckout.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "Shop", url: "/clinic/shop" },
              { title: "Product Checkout", active: true },
            ],
            pageTitle: "Fresh Clinics | Product Checkout",
            rule: "editor",
          },
        },
        {
          path: "order-complete",
          name: "ClinicOrderComplete",
          component: () =>
            import("@/views/clinic/ecommerce/ClinicOrderComplete.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "Shop", url: "/clinic/shop" },
              { title: "Order Complete", active: true },
            ],
            pageTitle: "Fresh Clinics | Order Complete",
            rule: "editor",
          },
        },
        {
          path: "orders",
          name: "OrderList",
          component: () =>
            import("@/views/clinic/ecommerce/order/OrderList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "Orders", active: true },
            ],
            pageTitle: "Fresh Clinics | Orders",
            rule: "editor",
          },
        },
        {
          path: "orders/:orderId",
          name: "OrderDetail",
          component: () =>
            import("@/views/clinic/ecommerce/order/OrderDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "Orders", url: "/clinic/orders" },
              { title: "Order Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Order Detail",
            rule: "editor",
          },
        },
      ],
    },
    /******************* ROUTE FOR CLINIC PROFILE COMPLETE STARTS***********************/
    {
      path: "/clinic-complete-profile",
      component: () =>
        import(
          "@/views/pages/register/OnboardingSubComponents/CompleteClinicProfile"
        ),
      meta: {
        rule: "editor",
      },
    },
    /******************* ROUTE FOR CLINIC PROFILE COMPLETE ENDS***********************/
    {
      path: "/register",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      meta: {
        rule: "editor",
      },
      children: [
        {
          path: "",
          name: "Register",
          component: () => import("@/views/pages/register/WebRegister.vue"),
          meta: {
            pageTitle: "Register",
            rule: "editor",
          },
        },
      ],
    },
    {
      path: "/drugbook",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      meta: {
        rule: "editor",
      },
      children: [
        {
          path: "",
          name: "auth-drugbook",
          component: () => import("@/views/org-owner/drug-book/Drugbook.vue"),
          meta: {
            pageTitle: "Drugbook",
            rule: "editor",
          },
        },
      ],
    },
    {
      path: "/drugbook/entries",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      meta: {
        rule: "editor",
      },
      children: [
        {
          path: "",
          name: "auth-drugbook-entries",
          component: () => import("@/views/org-owner/drug-book/drugbookEntries.vue"),
          meta: {
            pageTitle: "Drugbook | Entries",
            rule: "editor",
          },
        },
      ],
    },
    {
      path: "/drugbook/setup",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      meta: {
        rule: "editor",
      },
      children: [
        {
          path: "",
          name: "auth-drugbook-setup",
          component: () => import("@/views/org-owner/drug-book/SetUpDrugbook.vue"),
          meta: {
            pageTitle: "Drugbook | Set up",
            rule: "editor",
          },
        },
      ],
    },
    {
      path: "/drugbook/setup/clinic",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      meta: {
        rule: "editor",
      },
      children: [
        {
          path: "",
          name: "auth-drugbook-setup-clinic",
          component: () => import("@/views/org-owner/drug-book/TransferClinicDrugbook.vue"),
          meta: {
            pageTitle: "Drugbook | Set up",
            rule: "editor",
          },
        },
      ],
    },
    {
      path: "/drugbook/article",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      meta: {
        rule: "editor",
      },
      children: [
        {
          path: "",
          name: "drugbook-article",
          component: () => import("@/views/components/drugbook/IncorrectBalanceRedirect.vue"),
          meta: {
            pageTitle: "Drugbook | Incorrect Balance",
            rule: "editor",
          },
        },
      ],
    },
    {
      path: "*",
      redirect: "/pages/error-404",
    },

    /******************* ROUTE FOR NURSE PORTAL START***********************/
    ...nurseRoutes,
    /******************* ROUTE FOR NURSE PORTAL ENDS***********************/

    /******************* ROUTE FOR ADMIN PORTAL START***********************/
    {
      path: "/admin",
      redirect: "/admin/dashboard",
      meta: {
        rule: "editor",
      },
      component: () => import("@/layouts/main/admin/adminMain.vue"),
      children: [
        {
          path: "dashboard",
          name: "admin-dashboard",
          component: () => import("@/views/admin/AdminDashboard.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Dashboard",
            rule: "editor",
          },
        },
        /*** Page Management route started ***/
        {
          path: "pages",
          component: () => import("./views/admin/pages/CMSPage.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Pages | List",
            rule: "editor",
            //'authRequired': true
          },
        },
        {
          path: "pages/:id",
          name: "AdminCMSPageDetails",
          component: () => import("./views/admin/pages/CMSPageDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Pages", url: "/admin/pages" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Edit Page Details",
            rule: "editor",
            //'authRequired': true
          },
        },

        /****ADMIN PUSH NOTIFICATION ROUTE STARTED****/
        {
          path: "push-notification",
          name: "AdminPushNotification",
          component: () =>
            import("./views/components/push-notification/List.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Push Notification", active: true },
            ],
            pageTitle: "Fresh Clinics | Push Notification | List",
            rule: "editor",
          },
        },
        /*PAIR DEVICE ROUTE STARTED*/
        {
          path: "pair-device",
          name: "pairDevice",
          component: () => import("@/views/franchise/clinics/pairDevices.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Pair Device",
            rule: "editor",
          },
        },
        {
          path: "push-notification/send",
          name: "AdminSendPushNotification",
          component: () =>
            import("./views/components/push-notification/Send.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              {
                title: "Push Notification",
                url: "/admin/push-notification",
              },
              { title: "Send", active: true },
            ],
            pageTitle: "Fresh Clinics | Push Notification | Send",
            rule: "editor",
          },
        },
        /****SUPER ADMIN PUSH NOTIFICATION ROUTE ENDED****/

        /****ADMIN DOCTOR AGGREGATED REPORT ROUTE STARTED****/
        {
          path: "doctor-aggregated-report",
          name: "AdminDoctorAggregatedReport",
          component: () =>
            import("./views/components/doctor-aggregated-report/List.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Doctor Aggregated Report", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctor Aggregated Report | List",
            rule: "editor",
          },
        },
        /****ADMIN DOCTOR AGGREGATED REPORT ROUTE ENDED****/
        /****ADMIN INVOICE ROUTE ENDED****/
        {
          path: "invoice",
          name: "AdminInvoice",
          component: () => import("./views/components/invoice/List.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Invoice", active: true },
            ],
            pageTitle: "Fresh Clinics | Invoice | List",
            rule: "editor",
          },
        },
        /****ADMIN INVOICE ROUTE ENDED****/
        /****ADMIN PROFILE ROUTE STARTED****/
        {
          path: "my-account",
          name: "admin-my-account",
          component: () => import("@/views/admin/myAccount.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Profile", active: true },
            ],
            pageTitle: "Fresh Clinics | My Account",
            rule: "editor",
          },
        },
        {
          path: "profile",
          name: "admin-profile",
          component: () => import("@/views/admin/AdminProfile.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Profile", active: true },
            ],
            pageTitle: "Fresh Clinics | Profile",
            rule: "editor",
          },
        },
        {
          path: "change-password",
          name: "AdminChangePassword",
          component: () => import("@/views/pages/changePassword.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Change Password", active: true },
            ],
            pageTitle: "Fresh Clinics | Change Password",
            rule: "editor",
          },
        },
        {
          path: "success",
          name: "admin-change-password-success",
          component: () => import("@/views/pages/Success.vue"),
          meta: {
            rule: "editor",
          },
        },
        /****EMAIL TEMPLATE MANAGEMENT ROUTE STARTED****/
        {
          path: "email-templates",
          name: "email-template",
          component: () => import("@/views/admin/email/EmailTemplate.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Email Templates | List",
            rule: "editor",
          },
        },
        {
          path: "email-templates/:id",
          name: "admin-edit-email-template",
          component: () => import("@/views/admin/email/EditEmailTemplate.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Email Templates", url: "/admin/email-templates" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Email Templates | Edit",
            rule: "editor",
          },
        },

        /****CLINIC MANAGEMENT ROUTE STARTED****/ {
          path: "clinics",
          name: "AdminClinic",
          component: () => import("@/views/admin/clinics/clinics.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | List",
            rule: "editor",
          },
        },
        {
          path: "clinics/create",
          name: "AdminClinicCreate",
          component: () => import("@/views/admin/clinics/createClinic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Clinics", url: "/admin/clinics" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Create",
            rule: "editor",
          },
        },
        {
          path: "clinics/:clinicId/edit",
          name: "AdminClinicEdit",
          component: () => import("@/views/admin/clinics/clinicsEdit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Clinics", url: "/admin/clinics" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Edit",
            rule: "editor",
          },
        },
        {
          path: "clinics/:clinicId/treatment-history",
          name: "AdminClinicTreatmentHistory",
          component: () =>
            import("@/views/clinic/history/TreatmentHistory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Clinics", url: "/admin/clinics" },
              { title: "Treatment History", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Treatment History",
            rule: "editor",
          },
        },
        {
          path: "clinics/:clinicId/detail",
          name: "AdminClinicDetail",
          component: () => import("@/views/admin/clinics/ClinicDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Clinics", url: "/admin/clinics" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Clinics | Detail",
            rule: "editor",
          },
        },

        /****FRANCHISE MANAGEMENT ROUTE STARTED****/
        {
          path: "franchises",
          name: "admin-franchise-management",
          component: () => import("./views/admin/franchise/franchiseList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | List",
            rule: "editor",
          },
        },
        {
          path: "franchises/create",
          name: "admin-franchise-management-create",
          component: () =>
            import("./views/admin/franchise/createFranchise.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Franchises", url: "/admin/franchises" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | Create",
            rule: "editor",
          },
        },
        {
          path: "franchises/:franchiseId",
          name: "admin-franchise-management-edit",
          component: () => import("./views/admin/franchise/viewFranchise.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Franchises", url: "/admin/franchises" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | Edit",
            rule: "editor",
          },
        },
        {
          path: "franchises/:franchiseId/doctors/create",
          name: "AdminFranchiseManagementDoctorsCreate",
          component: () =>
            import("./views/admin/franchise/createAdminFranchiseDoctor.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Franchises", url: "/admin/franchises" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | Edit",
            rule: "editor",
          },
        },
        {
          path: "franchises/:franchiseId/nurses/create",
          name: "AdminFranchiseNursesCreate",
          component: () =>
            import("./views/admin/franchise/createAdminFranchiseNurse.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Franchises", url: "/admin/franchises" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | Edit",
            rule: "editor",
          },
        },
        {
          path: "franchises/:franchiseId/clinics/create",
          name: "AdminFranchiseClinicsCreate",
          component: () =>
            import("./views/admin/franchise/createAdminFranchiseClinic.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Franchises", url: "/admin/franchises" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | Edit",
            rule: "editor",
          },
        },
        {
          path: "franchises/:franchiseId/associated-clinics",
          name: "admin-franchise-management-associated-clinic-list-view",
          component: () =>
            import("./views/admin/franchise/viewFranchiseClinicList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Franchises", url: "/admin/franchises" },
              { title: "Clinics", url: "/admin/clinics" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Franchises | Associated Clinics | List",
            rule: "editor",
          },
        },

        /********patient management route started********/
        // {
        //   path: "patients",
        //   name: "PatientManagement",
        //   component: () => import("@/views/admin/patient/PatientList.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/admin/dashboard" },
        //       { title: "List", active: true },
        //     ],
        //     pageTitle: "Fresh Clinics | Patients | List",
        //     rule: "editor",
        //   },
        // },
        // {
        //   path: "patients/:patientId",
        //   name: "SubAdminPatientDetail",
        //   component: () =>
        //     import("@/views/admin/patient/SubAdminPatientDetails.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/admin/dashboard" },
        //       { title: "Patients", url: "/admin/patients" },
        //       { title: "Detail", active: true },
        //     ],
        //     pageTitle: "Fresh Clinics | Patients | Detail",
        //     rule: "editor",
        //   },
        // },
        // {
        //   path: "patients/:patientId/treatment/:checkinId",
        //   name: "SubAdminPatientTreatmentDetail",
        //   component: () =>
        //     import("@/views/admin/patient/PatientTreatmentDetails.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/super-admin/dashboard" },
        //       { title: "Patients", url: "/super-admin/patients" },
        //       { title: "Treatment Detail", active: true },
        //     ],
        //     pageTitle: "Fresh Clinics | Patients | Treatment Detail",
        //     rule: "editor",
        //   },
        // },
        // {
        //   path: "patients/:patientId/treatment/:checkinId",
        //   name: "AdminPatientTreatmentDetail",
        //   component: () =>
        //     import("@/views/admin/patient/PatientTreatmentDetails.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/admin/dashboard" },
        //       { title: "Patients", url: "/admin/patients" },
        //       { title: "Treatment Detail", active: true },
        //     ],
        //     pageTitle: "Fresh Clinics | Patients | Treatment Detail",
        //     rule: "editor",
        //   },
        // },
        /* DOCTORS MANAGEMENT STARTED */
        {
          path: "doctors",
          name: "AdminDoctor",
          component: () => import("@/views/admin/doctors/doctorList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | List",
            rule: "editor",
          },
        },
        {
          path: "doctors/:userId/profile-update-review",
          name: "AdminDoctorProfileReview",
          component: () =>
            import("@/views/general-component/updateProfileReview.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Doctors", url: "/admin/doctors" },
              { title: "Updated Profile Review", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Updated Profile Review",
            rule: "editor",
          },
        },
        {
          path: "doctors/create",
          name: "AdminDoctorCreate",
          component: () => import("@/views/admin/doctors/createDoctor.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Doctors", url: "/admin/doctors" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Create ",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId/edit",
          name: "AdminDoctorEdit",
          component: () => import("@/views/admin/doctors/doctorEdit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Doctors", url: "/admin/doctors" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Edit",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId",
          name: "AdminDoctorView",
          component: () => import("@/views/admin/doctors/doctorDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Doctors", url: "/admin/doctors" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Detail",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId/detail",
          name: "SubAdminDoctorDetail",
          component: () => import("@/views/general-component/UserDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Doctors", url: "/admin/doctors" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Detail",
            rule: "editor",
          },
        },
        /* NURSE MANAGEMENT ROUTE STARTED*/
        {
          path: "nurses/:nurseId/detail",
          name: "SubAdminNurseDetail",
          component: () => import("@/views/general-component/UserDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Nurses", url: "/admin/nurses" },
              { title: "Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Detail",
            rule: "editor",
          },
        },
        {
          path: "nurses/:userId/profile-update-review",
          name: "AdminNurseProfileReview",
          component: () =>
            import("@/views/general-component/updateProfileReview.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Nurses", url: "/admin/nurses" },
              { title: "Updated Profile Review", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Updated Profile Review",
            rule: "editor",
          },
        },
        {
          path: "nurses",
          name: "AdminNurses",
          component: () => import("./views/admin/nurses/NurseList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | list",
            rule: "editor",
          },
        },
        {
          path: "nurses/create",
          name: "AdminNurseCreate",
          component: () => import("./views/admin/nurses/NurseCreate.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Nurses", url: "/admin/nurses" },
              { title: "Create", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Create ",
            rule: "editor",
          },
        },
        {
          path: "nurses/:nurseId",
          name: "AdminNurseDetails",
          component: () => import("./views/admin/nurses/NurseDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Nurses", url: "/admin/nurses" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Edit",
            rule: "editor",
          },
        },
        {
          path: "nurses/:nurseId/patient/:patientId/treatment/:checkinId",
          name: "SubAdminNurseTreatmentDetail",
          component: () =>
            import("./views/admin/nurses/TreatmentHistoryDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Nurses", url: "/admin/nurses" },
              { title: "Treatment Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Treatment Detail",
            rule: "editor",
          },
        },
        {
          path: "doctors/:doctorId/patient/:patientId/treatment/:checkinId",
          name: "SubAdminDoctorTreatmentDetail",
          component: () =>
            import("./views/admin/doctors/TreatmentHistoryDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Doctors", url: "/admin/doctors" },
              { title: "Treatment Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Doctors | Treatment Detail",
            rule: "editor",
          },
        },
        {
          path: "nurses/:patientId/treatment/:checkinId",
          name: "AdminNurseTreatmentDetail",
          component: () =>
            import("./views/admin/nurses/TreatmentHistoryDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Nurses", url: "/admin/nurses" },
              { title: "Treatment Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Nurses | Treatment Detail",
            rule: "editor",
          },
        },
        {
          path: "notifications",
          name: "subAdminNotification",
          component: () => import("@/views/admin/NotificationList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Notifications | List",
            rule: "editor",
          },
        },
        {
          path: "settings",
          name: "subAdminSettings",
          component: () => import("@/views/components/AdminSettings.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Settings", active: true },
            ],
            pageTitle: "Fresh Clinics | Settings",
            rule: "editor",
          },
        },
        {
          path: "permission-denied",
          name: "AdminPermissionDenied",
          component: () => import("@/views/components/PermissionDenied.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Permission Denied", active: true },
            ],
            pageTitle: "Fresh Clinics | Permission Denied",
            rule: "editor",
          },
        },
        {
          path: "store/products",
          name: "AdminStoreProducts",
          component: () =>
            import("@/views/components/WebStore/products/Products.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Products", active: true },
            ],
            pageTitle: "Fresh Clinics | Products",
            rule: "editor",
          },
        },
        {
          path: "store/products/create",
          name: "AdminStoreProductsCreate",
          component: () =>
            import("@/views/components/WebStore/products/CreateProducts.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Product List", url: "/admin/store/products" },
              { title: "Create Product", active: true },
            ],
            pageTitle: "Fresh Clinics | Create Product",
            rule: "editor",
          },
        },
        {
          path: "store/products/:id/edit",
          name: "AdminStoreProductsEdit",
          component: () =>
            import("@/views/components/WebStore/products/EditProducts.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Product List", url: "/admin/store/products" },
              { title: "Edit Product", active: true },
            ],
            pageTitle: "Fresh Clinics | Edit Product",
            rule: "editor",
          },
        },
        {
          path: "store/products/:id/edit-variants",
          name: "AdminStoreProductsEditVariants",
          component: () =>
            import(
              "@/views/components/WebStore/products/EditProductVariants.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Product List", url: "/admin/store/products" },
              { title: "Edit Product Variants", active: true },
            ],
            pageTitle: "Fresh Clinics | Edit Product Variants",
            rule: "editor",
          },
        },
        // supplier routes admin  portal
        {
          path: "store/suppliers",
          name: "adminStoreSuppliers",
          component: () =>
            import("@/views/admin/store/supplier/ListSuppliers.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Suppliers", active: true },
            ],
            pageTitle: "Fresh Clinics | Suppliers | List",
            rule: "editor",
          },
        },
        {
          path: "store/suppliers/add",
          name: "adminStoreAddSuppliers",
          component: () =>
            import("./views/admin/store/supplier/CreateSupplier.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Supplier list", url: "/admin/store/suppliers" },
              { title: "Add", active: true },
            ],
            pageTitle: "Add Supplier",
            rule: "editor",
          },
        },
        {
          path: "store/suppliers/view/:supplierId",
          name: "adminStoreViewSuppliers",
          component: () => import("./views/admin/store/supplier/ViewSupplier"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Supplier list", url: "/admin/store/suppliers" },
              { title: "Details", active: true },
            ],
            pageTitle: "View Details",
            rule: "editor",
          },
        },
        {
          path: "store/suppliers/edit/:supplierId",
          name: "adminStoreEditSuppliers",
          component: () => import("./views/admin/store/supplier/EditSupplier"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Supplier list", url: "/admin/store/suppliers" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Edit Supplier",
            rule: "editor",
          },
        },
        {
          path: "store/suppliers/:supplierId/order/:orderId",
          name: "adminStoreViewSuppliersOrderDetail",
          component: () => import("./views/admin/store/supplier/ViewOrder"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Supplier list", url: "/admin/store/suppliers" },
              { title: "Order Details", active: true },
            ],
            pageTitle: "View Order Details",
            rule: "editor",
          },
        },
        //category routes admin portal
        {
          path: "store/categories",
          name: "AdminCategoryManagement",
          component: () =>
            import("./views/admin/store/category/listCategory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Categories", active: true },
            ],
            pageTitle: "Fresh Clinics | Categories | List",
            rule: "editor",
          },
        },
        {
          path: "categories/add",
          name: "AdminAddCategory",
          component: () =>
            import("./views/admin/store/category/addCategory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Category list", url: "/admin/store/categories" },
              { title: "Add", active: true },
            ],
            pageTitle: "Add Category",
            rule: "editor",
          },
        },
        {
          path: "categories/edit/:categoryId",
          name: "AdminEditCategory",
          component: () =>
            import("./views/admin/store/category/editCategory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Category list", url: "/admin/store/categories" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Edit Category",
            rule: "editor",
          },
        },
        // admin store order routes
        {
          path: "store/orders",
          name: "AdminStoreOrders",
          component: () => import("./views/admin/store/order/ListOrders"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Orders", active: true },
            ],
            pageTitle: "Fresh Clinics | Orders | List",
            rule: "editor",
          },
        },
        {
          path: "store/orders/view/:orderId",
          name: "AdminStoreViewOrder",
          component: () => import("./views/admin/store/order/ViewOrder"),
          meta: {
            breadcrumb: [{ title: "Details", active: true }],
            pageTitle: "View Details",
            rule: "editor",
          },
        },
        /*** Treatment Types route started ***/
        {
          path: "treatment-types",
          name: "adminTreatmentTypes",
          component: () => import("./views/components/treatmentTypes/List"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Treatment Types", active: true },
            ],
            pageTitle: "List",
            rule: "editor",
          },
        },
        {
          path: "treatment-types/:id/edit",
          name: "adminTreatmentTypesEdit",
          component: () => import("./views/components/treatmentTypes/Edit"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/dashboard" },
              { title: "Treatment Types", url: "/admin/treatment-types" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Edit",
            rule: "editor",
          },
        },
        /****************** ADMIN SHOP */
        {
          path: "shop",
          name: "AdminOrderCenter",
          component: () => import("@/views/clinic/ecommerce/OrderCenter.vue"),
          meta: {
            // breadcrumb: [
            //   { title: "Home", url: "/admin/shop" },
            //   { title: "Shop", active: true },
            // ],
            pageTitle: "Fresh Clinics | Shop",
            rule: "editor",
          },
        },
        {
          path: "product/:id",
          name: "AdminProductDetail",
          component: () => import("@/views/clinic/ecommerce/ProductDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/shop" },
              { title: "Shop", url: "/admin/shop" },
              { title: "Product Details", active: true },
            ],
            pageTitle: "Fresh Clinics | Product Detail",
            rule: "editor",
          },
        },
        {
          path: "checkout",
          name: "AdminProductCheckout",
          component: () =>
            import("@/views/clinic/ecommerce/ClinicCheckout.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/shop" },
              { title: "Shop", url: "/admin/shop" },
              { title: "Product Checkout", active: true },
            ],
            pageTitle: "Fresh Clinics | Product Checkout",
            rule: "editor",
          },
        },
        {
          path: "order-complete",
          name: "AdminOrderComplete",
          component: () =>
            import("@/views/clinic/ecommerce/ClinicOrderComplete.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/shop" },
              { title: "Shop", url: "/admin/shop" },
              { title: "Order Complete", active: true },
            ],
            pageTitle: "Fresh Clinics | Order Complete",
            rule: "editor",
          },
        },
        {
          path: "orders",
          name: "AdminOrderList",
          component: () =>
            import("@/views/clinic/ecommerce/order/OrderList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/shop" },
              { title: "Orders", active: true },
            ],
            pageTitle: "Fresh Clinics | Orders",
            rule: "editor",
          },
        },
        {
          path: "orders/:orderId",
          name: "AdminOrderDetail",
          component: () =>
            import("@/views/clinic/ecommerce/order/OrderDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin/shop" },
              { title: "Orders", url: "/admin/orders" },
              { title: "Order Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Order Detail",
            rule: "editor",
          },
        },
        /****************** ADMIN END SHOP */
      ],
    },
    /******************* ROUTE FOR ADMIN PORTAL ENDS***********************/
    /******************* ROUTE FOR BASIC PORTAL START***********************/
    ...basicRoutes,
    /******************* ROUTE FOR BASIC PORTAL END***********************/
    /******************* ROUTE FOR DERMA SPECIALIST PORTAL START***********************/
    {
      path: "/derma-specialist",
      redirect: "/derma-specialist/shop",
      component: () =>
        import("@/layouts/main/derma-specialist/DermaSpecialistMain.vue"),
      meta: {
        rule: "editor",
      },
      children: [
        {
          path: "notice-board",
          name: "notice-board",
          component: () => import("@/views/basic/Dashboard.vue"),
          meta: {
            pageTitle: "Fresh Clinics | My Organisations",
            rule: "editor",
          },
        },
        {
          path: "events",
          name: "events",
          component: () => import("@/views/basic/Events.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Events",
            rule: "editor",
          },
        },
        {
          path: "resources",
          name: "resources",
          component: () => import("@/views/basic/Resources.vue"),
          meta: {
            pageTitle: "Fresh Clinics | Resources",
            rule: "editor",
          },
        },
        {
          path: "shop",
          name: "DoctorClinicOrderCenter",
          component: () => import("@/views/clinic/ecommerce/OrderCenter.vue"),
          meta: {
            // breadcrumb: [
            //   { title: "Home", url: "/doctor-clinic/shop" },
            //   { title: "Shop", active: true },
            // ],
            pageTitle: "Fresh Clinics | Shop",
            rule: "editor",
          },
        },
        {
          path: "addCardDetails",
          name: "DoctorClinicAddCard",
          component: () => import("@/views/clinic/ClinicAddCard.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Add Card", active: true },
            ],
            pageTitle: "Fresh Clinics | Add Card",
            rule: "editor",
          },
        },
        {
          path: "success",
          name: "clinic-change-password-success",
          component: () => import("@/views/pages/Success.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "settings/products-list",
          name: "Clinic Products List",
          component: () => import("@/views/clinic/settings/ProductsList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Settings | Products List",
            rule: "editor",
          },
        },
        {
          path: "settings/treatment-areas",
          name: "Clinic Treatment Areas",
          component: () => import("@/views/clinic/settings/TreatmentArea.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/clinic/dashboard" },
              { title: "List", active: true },
            ],
            pageTitle: "Fresh Clinics | Settings | Treatment Areas",
            rule: "editor",
          },
        },
        {
          path: "settings",
          name: "DoctorClinicSettings",
          component: () =>
            import(
              "@/views/derma-specialist/settings/DoctorClinicSettings.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Settings", active: true },
            ],
            pageTitle: "Fresh Clinics | Settings",
            rule: "editor",
          },
        },
        {
          path: "product/:id",
          name: "DoctorClinicProductDetail",
          component: () => import("@/views/clinic/ecommerce/ProductDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Shop", url: "/doctor-clinic/shop" },
              { title: "Product Details", active: true },
            ],
            pageTitle: "Fresh Clinics | Product Detail",
            rule: "editor",
          },
        },
        {
          path: "checkout",
          name: "DoctorClinicProductCheckout",
          component: () =>
            import("@/views/clinic/ecommerce/ClinicCheckout.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Shop", url: "/doctor-clinic/shop" },
              { title: "Product Checkout", active: true },
            ],
            pageTitle: "Fresh Clinics | Product Checkout",
            rule: "editor",
          },
        },
        {
          path: "order-complete",
          name: "DoctorClinicOrderComplete",
          component: () =>
            import("@/views/clinic/ecommerce/ClinicOrderComplete.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Shop", url: "/doctor-clinic/shop" },
              { title: "Order Complete", active: true },
            ],
            pageTitle: "Fresh Clinics | Order Complete",
            rule: "editor",
          },
        },
        {
          path: "orders",
          name: "DoctorOrderList",
          component: () =>
            import("@/views/clinic/ecommerce/order/OrderList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Orders", active: true },
            ],
            pageTitle: "Fresh Clinics | Orders",
            rule: "editor",
          },
        },
        {
          path: "orders/:orderId",
          name: "DoctorClinicOrderDetail",
          component: () =>
            import("@/views/clinic/ecommerce/order/OrderDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Orders", url: "/doctor-clinic/orders" },
              { title: "Order Detail", active: true },
            ],
            pageTitle: "Fresh Clinics | Order Detail",
            rule: "editor",
          },
        },
        {
          path: "setup-bank-detail",
          name: "DoctorClinicBankDetails",
          component: () =>
            import("@/views/clinic/ClinicPaymentMethodSetup.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Profile", url: "/doctor-clinic/profile" },
              { title: "Payment Details", active: true },
            ],
            pageTitle: "Fresh Clinics | Bank Details",
            rule: "editor",
          },
        },
        {
          path: "profile",
          name: "DoctorProfile",
          component: () => import("@/views/clinic/ClinicProfile.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Profile", active: true },
            ],
            pageTitle: "Fresh Clinics | Profile",
            rule: "editor",
          },
        },
        {
          path: "change-password",
          name: "DoctorClinicChangePassword",
          component: () => import("@/views/pages/changePassword.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dotor-clinic/shop" },
              { title: "Change Password", active: true },
            ],
            pageTitle: "Fresh Clinics | Change Password",
            rule: "editor",
          },
        },
        {
          path: "orders",
          name: "OrderList",
          component: () =>
            import("@/views/clinic/ecommerce/order/OrderList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/doctor-clinic/shop" },
              { title: "Orders", active: true },
            ],
            pageTitle: "Fresh Clinics | Orders",
            rule: "editor",
          },
        },
      ],
    },
    /******************* ROUTE FOR DERMA SPECIALIST PROFILE COMPLETE ENDED***********************/
  ],
});

router.afterEach((to) => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
  document.title = to.meta.pageTitle || "Fresh Clinics";
});

router.beforeEach((to, from, next) => {
  analytics.page();

  // if(
  //   !(
  //     (store.state.AppActiveUser.financialDetails && store.state.AppActiveUser.financialDetails.accountNumber) ||
  //     (store.state.AppActiveUser.defaultPaymentMethod === 'CreditCard' && store.state.AppActiveUser.paymentDetails.cardId)
  //   )
  // ) {
  //   if(store.state.AppActiveUser.userRole === "clinic" &&
  //     to.fullPath !== "/clinic/setup-bank-detail" &&
  //     to.fullPath !== "/login" ) {
  //       return next("/clinic/setup-bank-detail");
  //   }
  // }

  // GIDEON: Remove this will not be checking isProfileCompleted
  // if (
  //   store.state.AppActiveUser.userRole === "clinic" &&
  //   !store.state.AppActiveUser.isProfileCompleted &&
  //   to.fullPath !== "/clinic-complete-profile" &&
  //   to.fullPath !== "/login"
  // ) {
  //   return next("/clinic-complete-profile");
  // }

  if (
    to.path !== "/login" &&
    to.path !== "/register" &&
    store.state.AppActiveUser &&
    (store.state.AppActiveUser.userRole === "doctor" ||
      store.state.AppActiveUser.userRole === "nurse" ||
      store.state.AppActiveUser.userRole === "superAdmin" ||
      store.state.AppActiveUser.userRole === "doctorClinic" ||
      store.state.AppActiveUser.userRole === "orgOwner" ||
      store.state.AppActiveUser.userRole === "admin")
  ) {
    store.dispatch("general/fetchNotificationBadge");
  }

  // if (
  //   to.path !== "/login" &&
  //   to.path !== "/register" &&
  //   store.state.AppActiveUser &&
  //   store.state.AppActiveUser.userRole === "doctorClinic" &&
  //   store.state.AppActiveUser.doctorAssociatedClinicIds.length > 0 &&
  //   !sessionStorage.getItem("doctorClinicSelectedClinic")
  // ) {
  //   // sessionStorage.setItem(
  //   //   "doctorClinicSelectedClinic",
  //   //   store.state.AppActiveUser.doctorAssociatedClinicIds[0].clinicId.id
  //   // );
  // }
  const exceptRoute = [
    "/verify-email/" + to.params.token,
    "/onboarding/" + to.params.token,
    "/register/doctor",
    "/register/business",
  ];
  if (!exceptRoute.includes(to.path)) {
    auth(to, from, next);
    return next();
  } else {
    return next();
  }
});

export default router;
